import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import icon_chevron from '../../assets/icon_chevron.svg';
import icon_clock from '../../assets/icon_clock.svg';

const StartEndTime = ({
    handleChange,
    index,
    selectedFromHour,
    selectedFromMin,
    selectedToHour,
    selectedToMin
}) => {
    const [startTimeToFilter, setStartTimeToFilter] = useState('06:00')
    const [endTimeToFilter, setEndTimeToFilter] = useState('14:30')
    const [allTimeOptions, setAllTimeOptions] = useState([])
    const [endTimeOptions, setEndTimeOptions] = useState([])
    const [timeChanged, setTimeChanged] = useState(false)
    const endTimeRef = useRef(null)
    let convertedFromHour
    let convertedFromMin
    let convertedToHour
    let convertedToMin
    let convertedFromTime
    let convertedToTime

    useEffect(() => {
        //if schedule editor, format time
        if (selectedFromHour) {
            if (selectedFromHour <= 9 || selectedToHour <= 9) {
                convertedFromHour = selectedFromHour.toString().padStart(2, '0')
                convertedToHour = selectedToHour.toString().padStart(2, '0')
            } else {
                convertedFromHour = selectedFromHour.toString()
                convertedToHour = selectedToHour.toString()
            }

            if (selectedFromMin === 0 || selectedToMin === 0) {
                convertedFromMin = selectedFromMin.toString().padStart(2, '0')
                convertedToMin = selectedToMin.toString().padStart(2, '0')
            } else {
                convertedFromMin = selectedFromMin.toString()
                convertedToMin = selectedToMin.toString()
            }

            convertedFromTime = `${convertedFromHour}:${convertedFromMin}`
            convertedToTime = `${convertedToHour}:${convertedToMin}`

            setStartTimeToFilter(convertedFromTime)
            setEndTimeToFilter(convertedToTime)

            optionsArray(convertedFromTime)
        } else {
            optionsArray(startTimeToFilter)
        }
    }, [])

    const optionsArray = (startTime) => {
        let tempAllOptions = []
        let tempEndOptions = []

        //Set time range for start and end options
        for (let i=0; i < 25; i++) {
            for (let j=0; j < 4; j++) {
                tempAllOptions.push(`${i < 10 ? `0${i}` : i}:${j === 0 ? `00` : 15*j}`)
            }
        }

        tempAllOptions = tempAllOptions.slice(1, -3)

        tempAllOptions.map((time) => {
            if (time > startTime) {
                tempEndOptions.push(time)
            }
        })

        setAllTimeOptions(tempAllOptions)
        setEndTimeOptions(tempEndOptions)
    }

    const handleTime = (e, type) => {
        handleChange(index, type, e.target.value)

        if (type === "startTime") {
            setStartTimeToFilter(e.target.value)
            setTimeChanged(true)
            optionsArray(e.target.value)
        } else if (type === "endTime") {
            setEndTimeToFilter(e.target.value)
        }
    }

    useEffect(() => {
        if (timeChanged) {
            //only change end time if current end time is outside of range
            if (endTimeToFilter < endTimeOptions[0]) {
                handleChange(index, "endTime", endTimeRef.current[0].value)
                setEndTimeToFilter(endTimeRef.current[0].value)
                setTimeChanged(false)
            }
        }
    }, [timeChanged])

    return (
        <TimeSettings className="time-container">
            <div className="start-time-container">
                <label>
                Start Time
                <div className="time-input-container">
                    <select
                        name="startTime"
                        id="start-time"
                        value={startTimeToFilter}
                        onChange={(e) => handleTime(e, 'startTime')}
                    >
                    {startTimeToFilter && allTimeOptions.map(option => (
                        <option key={`${option}-start-time`} value={option}>
                        {option}
                        </option>
                    ))}
                    </select>
                    <img src={icon_chevron} alt="icon to show time options" height="16" width="16" />
                </div>
                </label>
            </div>
            <div className="end-time-container">
                <label>
                End Time
                <div className="time-input-container">
                    <select
                        name="endTime"
                        id="end-time"
                        ref={endTimeRef}
                        value={endTimeToFilter}
                        onChange={(e) => handleTime(e, 'endTime')}
                    >
                    {startTimeToFilter && endTimeOptions.map(option => (
                        <option key={`${option}-end-time`} value={option}>
                        {option}
                        </option>
                    ))}
                    </select>
                    <img src={icon_chevron} alt="icon to show time options" height="16" width="16" />
                </div>
                </label>
            </div>
        </TimeSettings>
    )
}

const TimeSettings = styled.div`
    display: flex;
    flex-flow: column;
    height: auto;
    justify-content: space-between;
    max-height: 170px;
    width: 100%;

    label {
        font-weight: 500;
    }

    .time-input-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        position: relative;

        span {
            color: #02222B;
            font-size: 16px;
            font-weight: 500;
            left: 42px;
            line-height: 24px;
            pointer-events: none;
            position: absolute;
            top: 11px;
        }

        select {
            background: url(${icon_clock}) 15px center no-repeat;
            background-size: 17px 17px;
            border: 2px solid #EAECF0;
            box-shadow: none;
            color: #02222B;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            padding: 10px 55px 10px 70px;
            text-align: center;
            width: 100%;
            z-index: 2;
        }

        img {
            filter: opacity(0.5);
            position: absolute;
            right: 8px;
            top: 15px;
            z-index: 1;
        }
    }
`

export default StartEndTime