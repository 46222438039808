import React from 'react'
import styled from '@emotion/styled'

const Loading = ({ message }) => (
    <LoadingDiv>
        <div className="loading-container">
            <div className='processing'>{message}</div>
            <div className="loader"></div>
        </div>

        <div className="background-modal"></div>
    </LoadingDiv>
)

const LoadingDiv = styled.div`
    .loading-container {
        align-items: center;
        background-color: white;
        border-radius: 8px;
        display: flex;
        box-shadow: 0px 0px 30px 0px #02222B;
        flex-flow: column;
        justify-content: center;
        left: calc(50vw - 80px);
        padding: 24px 0;
        position: fixed;
        top: calc(50vh - 30px);
        width: 160px;
        z-index: 101;
    }
    
    .processing {
        color: #027A48;
        font-size: 12px;
        font-weight: 600;
        padding-bottom: 18px;
        text-align: center;
    }

    .loader {
        animation: spin 2s linear infinite;
        border: 8px solid #A6F4C5; 
        border-top: 8px solid #027A48;
        border-radius: 50%;
        height: 20px;
        width: 20px;
    }

    .background-modal {
        -webkit-backdrop-filter: blur(8px);
        background: rgba(52, 64, 84, 0.70);
        backdrop-filter: blur(8px);
        width: 100vw;
        height: 100vh;
        left: 0;
        position: absolute;
        z-index: 100;
        top: 0;
        overflow: hidden;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`

export default Loading