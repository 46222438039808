import React from 'react'
import styled from '@emotion/styled'

const Message = ({ displayMessage, type }) => (
    <MessageDiv className={type}>
        <div className='message'>{displayMessage}</div>
    </MessageDiv>
)

const MessageDiv = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 20px;

    &.error {
        background: #F04438;
    }

    &.success {
        background: #12B76A;
    }
    
    .message {
        color: white;
        font-size: 12px;
        font-weight: 600;
        padding: 10px;
    }
`

export default Message