import icon_keypad_lock from '../assets/icon_keypad_locked.svg'
import icon_keypad_unlock from '../assets/icon_keypad_unlocked.svg'
import icon_alert from '../assets/icon_alert.svg'
import icon_fan from '../assets/icon_fan.svg'
import icon_heating from '../assets/icon_heating.svg'
import icon_cooling from '../assets/icon_cooling.svg'
import icon_idle from '../assets/icon_idle.svg'
import icon_off from '../assets/icon_state_off.svg'
import icon_connectivity_problem from '../assets/icon_connectivity_problem.svg'

/*** function is meant to be an independent processor for the zone realtime data***/
const processRealtimeData = (zoneRealtimeData) => {

    // default values
    const returnRealtimeData = {
      currentTemperature: 'N/A',
      temperatureLowerBound: null,
      temperatureUpperBound: null,
      activity: <div><img src={icon_alert} alt='alert icon' width="15" /> None</div>,
      thermostatIndicator: null,
      zoneStatus: null,
      zoneStatusCSS: '',
      thermostatMode: null,
      temperatureClass: '',
      scheduleOrOverride: null,
      keypadLock: <div className="keypad-status zone-stat"><img src={icon_keypad_lock} alt='locked padlock icon' width="12" height="12" /><span>Locked</span></div>,
      controlState: null,
    }
  
    if (zoneRealtimeData) {
      if (zoneRealtimeData.temperature) {
        returnRealtimeData.currentTemperature = Math.round(zoneRealtimeData.temperature)
        returnRealtimeData.temperatureLowerBound = Math.round(zoneRealtimeData.temperatureLowerBound)
        returnRealtimeData.temperatureUpperBound = Math.round(zoneRealtimeData.temperatureUpperBound)
      
        // const boundRange = parseInt(zoneRealtimeData.temperatureUpperBound) - parseInt(zoneRealtimeData.temperatureLowerBound)
        // let indicatorPercent = 90 - Math.round((zoneRealtimeData.temperature - parseInt(zoneRealtimeData.temperatureLowerBound)) / boundRange * 90)
        // if (indicatorPercent > 90) {
        //   indicatorPercent = 90
        // }
        // else if (indicatorPercent < 0) {
        //   indicatorPercent = 0
        // }
  
        // if (!zoneRealtimeData.unmanaged) {
        //   returnRealtimeData.thermostatIndicator = <div className="zone-status-thermostat-indicator" style={{ top: indicatorPercent + '%' }}></div>
        // }
      }
  
      if (zoneRealtimeData.outOfRange) {
        returnRealtimeData.zoneStatus = 'Out of Range'
        returnRealtimeData.zoneStatusCSS = 'warn'
      } else {
        returnRealtimeData.zoneStatus = 'In Range'
        returnRealtimeData.zoneStatusCSS = 'healthy'
      }
  
      if (zoneRealtimeData.veryOutOfRange) {
        returnRealtimeData.zoneStatus = 'Very out of Range'
        returnRealtimeData.zoneStatusCSS = 'unhealthy'
      }
  
      if (zoneRealtimeData.offline) {
        returnRealtimeData.zoneStatus = 'Offline'
        returnRealtimeData.zoneStatusCSS = 'offline'
      }
  
      if (zoneRealtimeData.unmanaged) {
        returnRealtimeData.zoneStatus = 'Unmanaged'
        returnRealtimeData.zoneStatusCSS = 'unmanaged'
      }
  
      returnRealtimeData['keypadLock'] = <div className="keypad-status"><img src={icon_keypad_unlock} alt='unlocked padlock icon' width="18" height="18" /></div>
      if (zoneRealtimeData['localOverrideLocked']) {
        returnRealtimeData['keypadLock'] = <div className="keypad-status"><img src={icon_keypad_lock} alt='locked padlock icon' width="18" height="18" /></div>
      }
  
  
      if (zoneRealtimeData.activityState === 'Off') {
        returnRealtimeData.activityState = <div className='activity-state'><img src={icon_off} alt='off icon' width="18" height="18" /></div>
      } else if (zoneRealtimeData.activityState === 'Heating') {
        returnRealtimeData.activityState = <div className='activity-state'><img src={icon_heating} alt='heating icon' width="18" height="18" /></div>
      } else if (zoneRealtimeData.activityState === 'Cooling') {
        returnRealtimeData.activityState = <div className='activity-state'><img src={icon_cooling} alt='snowflake icon' width="18" height="18" /></div>
      } else if (zoneRealtimeData.activityState === 'Fan') {
        returnRealtimeData.activityState = <div className='activity-state'><img src={icon_fan} alt='fan icon' width="18" height="18" /></div>
      } else if (zoneRealtimeData.activityState === 'Idle') {
        returnRealtimeData.activityState = <div className='activity-state'><img src={icon_idle} alt='fan icon' width="18" height="18" /></div>
      } else {
        returnRealtimeData.activityState = <div className='activity-state'><img src={icon_alert} alt='alert icon' width="18" height="18" /></div>
      }
  
      if (zoneRealtimeData.unmanaged) {
        returnRealtimeData.controlState = "Unmanaged"
      } else if (zoneRealtimeData.hasControlProblem) {
        returnRealtimeData.controlState = "Control Problem"
      } else if (zoneRealtimeData.offline) {
        returnRealtimeData.controlState = "Connectivity Problem"
      } else if (zoneRealtimeData.elexityOptimized) {
        returnRealtimeData.controlState = "Elexity Optimized"
      }
  
      if (zoneRealtimeData.inOverride) {
        returnRealtimeData.scheduleOrOverride = `In Remote Override Until: ${zoneRealtimeData.overrideEnds}`
      } else if (zoneRealtimeData.inLocalOverride) {
        returnRealtimeData.scheduleOrOverride = `In Local Override Until: ${zoneRealtimeData.localOverrideEnds}`
      } else if (zoneRealtimeData.inEvent) {
        returnRealtimeData.scheduleOrOverride = `${zoneRealtimeData.eventName}`
      } else if (zoneRealtimeData.inSchedule) {
        returnRealtimeData.scheduleOrOverride = `${zoneRealtimeData.scheduleName}`
      } else if (zoneRealtimeData.unmanaged) {
        returnRealtimeData.scheduleOrOverride = "Unmanaged"
      } else {
        returnRealtimeData.scheduleOrOverride = ""
      }
    }
    else {
      returnRealtimeData['activity'] = false
    }
    return returnRealtimeData
}

export default processRealtimeData