import React from 'react';
import {connect} from 'react-redux';
import styled from '@emotion/styled';

import WithRouter from '../utilities/WithRouter';
import { setCurrentCampus } from '../actions';

import orientationVideo from '../assets/orientation_video.webm';
import adaptiveEnergyVideo from '../assets/adaptive_energy_management.webm';
import hvacClimateControls from '../assets/hvac_climate_controls.webm';

import icon_resources from '../assets/icon_resources.svg';

const Resources = ({ showMobile }) => {
    return (
      <ResourcesDiv className={showMobile ? 'mobile' : 'desktop'}>
          <div className="header">
              <img src={icon_resources} alt="information icon" width="18" height="18" />
              <h1>Resources</h1>
          </div>

          <h2>Tutorial Videos</h2>
          <section className="resources-section">
            <div className="resource-video">
              <h4>90 Second Orientation</h4>
              <video src={orientationVideo} width='320' height='240' controls />
            </div>

            <div className="resource-video">
              <h4>Adaptive Energy Management</h4>
              <video src={adaptiveEnergyVideo} width='320' height='240' controls />
            </div>
            
            <div className="resource-video">
              <h4>HVAC Climate Controls</h4>
              <video src={hvacClimateControls} width='320' height='240' controls />
            </div>
          </section>
      </ResourcesDiv>
    )
}

const ResourcesDiv = styled.section`
  color: #02222B;
  height: 100vh;
  overflow-x: scroll;

  .header {
    height: 33px;
  }

  .mobile {
    margin: 36px 24px;

    .resources-section {
      justify-content: center;
    }
  }

  h2 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .resources-section {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
  }

  .resource-video {
    video {
      height: auto;
      width: clamp(300px, 39vw, 500px);
    }
  }
`

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    currentUserData: state.currentUserData,
    campuses: state.campuses
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
        dispatch(setCurrentCampus(campusId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Resources))