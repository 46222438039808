const randomNum = Math.random().toString(36).substring(2,10)

const local_config = {
  'backend_host': 'http://localhost:8082',
  'auth0_domain': 'elexity-dev.us.auth0.com',
  'auth0_clientid': 'fFd3b123nw201svJkPAR7VJKCZtk1sFu',
  'auth0_callback': window.location.protocol + "//" + window.location.host + "/",
  'auth0_audience': 'https://api.dev.elexity.io',
  'random_num': 'state-' + randomNum,
  'logout_link': 'https://elexity-dev.us.auth0.com/v2/logout?returnTo=' + window.location.protocol + "//" + window.location.host + '/&client_id=fFd3b123nw201svJkPAR7VJKCZtk1sFu'
}

const demo01_config = {
  'backend_host': 'https://demo01.elexity.net:447',
  'auth0_domain': 'elexity-dev.us.auth0.com',
  'auth0_clientid': 'fFd3b123nw201svJkPAR7VJKCZtk1sFu',
  'auth0_callback': window.location.protocol + "//" + window.location.host + "/",
  'auth0_audience': 'https://api.dev.elexity.io',
  'random_num': 'state-' + randomNum,
  'logout_link': 'https://elexity-dev.us.auth0.com/v2/logout?returnTo=' + window.location.protocol + "//" + window.location.host + '/&client_id=fFd3b123nw201svJkPAR7VJKCZtk1sFu'
}

const sales01_config = {
  'backend_host': 'https://sales01.elexity.net:447',
  'auth0_domain': 'elexity-sales.us.auth0.com',
  'auth0_clientid': 'hVpPuU3dTb0HTRYjeXSt7NnetqDQa7mt',
  'auth0_callback': window.location.protocol + "//" + window.location.host + "/",
  'auth0_audience': 'https://api.sales.elexity.io',
  'random_num': 'state-' + randomNum,
  'logout_link': 'https://elexity-sales.us.auth0.com/v2/logout?returnTo=' + window.location.protocol + "//" + window.location.host + '/&client_id=hVpPuU3dTb0HTRYjeXSt7NnetqDQa7mt'
}

const elexity_prod_config = {
  'backend_host': 'https://koi.elexity.net:447',
  'auth0_domain': 'elexity-prod.us.auth0.com',
  'auth0_clientid': 'DpAhPzB1MITcdU3erXJw29NcnosRSWmx',
  'auth0_callback': window.location.protocol + "//" + window.location.host + "/",
  'auth0_audience': 'https://api.prod.elexity.io',
  'random_num': 'state-' + randomNum,
  'logout_link': 'https://elexity-prod.us.auth0.com/v2/logout?returnTo=' + window.location.protocol + "//" + window.location.host + '/&client_id=DpAhPzB1MITcdU3erXJw29NcnosRSWmx'
}

let configuration;

let getEnv = (env) => {
  switch (env) {
    case 'prod':
      configuration = elexity_prod_config
      break;
    case 'demo01':
      configuration = demo01_config
      break;
    case 'sales01':
      configuration = sales01_config
      break;
    case 'local':
      configuration = local_config
      break;
    default:
      configuration = local_config
      break;
  }  
}

getEnv(process.env.REACT_APP_ELEXITY_ENV)

// const configuration = 


// process.env.REACT_APP_ELEXITY_ENV === 'prod'
//    ? elexity_prod_config
//    : (process.env.REACT_APP_ELEXITY_ENV === 'local' ? local_config : demo01_config)

export default configuration
