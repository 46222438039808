import React from "react";

const Checkbox = ({ id, type, name, onChange, checked }) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      onChange={onChange}
      checked={checked}
    />
  );
};

export default Checkbox;