import React, { useState, useEffect, useRef } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import exportingModule from 'highcharts/modules/exporting'
import MomentTimeZone from 'moment-timezone'
import Moment from 'moment'
import styled from '@emotion/styled'

import ZoneCard from '../../components/ZoneCard'
import ComfortModalRemoteOverride from '../../components/ComfortModalRemoteOverride'
import ComfortModalScheduleEvent from '../../components/modals/ComfortModalScheduleEvent'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { elexity } from '../../utilities/AdvancedGraphHelper'
import AuthenticatedComponent from '../../utilities/AuthenticatedComponent'
import configuration from '../../config'
import authentication from '../../utilities/Authentication'

window.moment = Moment
MomentTimeZone()
Moment()

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

const ZoneSettings = ({
  currentCampusId,
  currentCampusData,
  currentBuildingId,
  buildingZoneData,
  currentZoneId,
  currentZoneData,
  buildingZoneCallback,
  zoneSummaryCallback,
  setCurrentScheduleId,
  allScheduleCallback,
  currentScheduleData,
  showMobile,
  openScheduleModal,
  zoneHealth,
  setZoneHealth
}) => {
  exportingModule(Highcharts)

  const authenticatedComponent = new AuthenticatedComponent

  const [selectedZoneModal, setSelectedZoneModal] = useState(null)
  const [currentLock, setCurrentLock] = useState(false)
  const [currentManaged, setCurrentManaged] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState("")
  const [chartTabActive, setChartTabActive] = useState('live')
  const [hasSchedule, setHasSchedule] = useState(false)
  const [hasEvent, setHasEvent] = useState(false)
  const [scheduleName, setScheduleName] = useState(false)
  const [eventName, setEventName] = useState(false)
  const [remoteOverride, setRemoteOverride] = useState(false)
  const [localOverride, setLocalOverride] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [modalMessage, setModalMessage] = useState("")
  const [confirmAction, setConfirmAction] = useState("")
  const [selectionManaged, setSelectionManaged] = useState(false)
  const [zoneSettingsClass, setZoneSettingsClass] = useState('')
  const [remoteSave, setRemoteSave] = useState(false)

  const graphSubtitle = `${currentZoneData?.longThermalZoneDisplayName}`
  const hasOverride = localOverride === true || remoteOverride === true
  const historyChartRef = useRef(null)
  const liveChartRef = useRef(null)
  const errorMessageRef = useRef(null)

  useEffect(() => {
    updateZoneData()
    authenticatedComponent.getAllScheduleData(currentCampusId, 'large', allScheduleCallback)

    let desktopInterval = setInterval(() => {
      if (currentZoneId !== null) {
        updateZoneData()
      }
    }, 5000)

    return () => clearInterval(desktopInterval)
  }, [])

  useEffect(() => {
    if (remoteSave === true) {
      setProcessing(true)
    } else {
      setProcessing(false)
    }

    if (currentZoneData) {
      setCurrentLock(currentZoneData.localOverrideLocked)
      setCurrentManaged(currentZoneData.unmanaged === false ? true : false)
      setCurrentSchedule(currentZoneData.scheduleName 
          ? currentZoneData.scheduleName 
          : ""
      )
      setHasSchedule(currentZoneData.hasSchedule)
      setHasEvent(currentZoneData.hasEvent)
      setScheduleName(currentZoneData.scheduleName)
      setEventName(currentZoneData.eventName)
      setRemoteOverride(currentZoneData.hasOverride)
      setLocalOverride(currentZoneData.inLocalOverride)
      setSelectionManaged(currentZoneData.unmanaged)
      
      if (currentZoneData.statusCategory === "Unmanaged" && zoneSettingsClass === '' && selectedZoneModal !== 'loading') {
        setZoneSettingsClass('unmanaged')
      }
    }
  }, [currentZoneData])

  useEffect(() => {
    if (historyChartRef.current && liveChartRef.current) {
      elexity.admin.monitor.graphs.initAuth(authenticatedComponent.generateAuthenticatedHeader())
      elexity.admin.monitor.graphs.init(
        currentZoneId,
        configuration['backend_host']
            + '/admin/monitor/{entityKey}/thermalZone/graphData/definition',
        configuration['backend_host']
            + '/admin/monitor/{entityKey}/thermalZone/graphData/history',
        configuration['backend_host']
            + '/admin/monitor/{entityKey}/thermalZone/graphData/live',
        graphConfiguration,
        "historyPerformanceGraph",
        "livePerformanceGraph",
        graphSubtitle
      )
    }
  }, [historyChartRef.current && liveChartRef.current])

  const graphConfiguration = ({
    seriesDefinitions : [
      {name: "Current Temperature", color: "#4A5578", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'high'}},
      {name: "Lower Bounds", color: "#0086C9", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'high'}},
      {name: "Target", color: "#16B364", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'high'}},
      {name: "Upper Bounds", color: "#E62E05", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'high'}},
      {name: "Outside Temperature", color: "#EAAA08", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'high'}}      
    ],
    
    plotBandColors : {
        'C' :  "#D1E9FF",
        'H' :  "#FEE4E2",
        'F' :  "#EAECF0"
    },
    
    defaultLiveZoom : 3 
  })

  function updateZoneData() {
    authenticatedComponent.getZoneSummary(currentZoneId, zoneSummaryCallback)
  }

  function handleRefresh() {
    elexity.admin.monitor.graphs.triggerHistoryGraphReload()
  }

  const clearModal = () => {
    setSelectedZoneModal(null)
  }
  
  const openZoneModal = (selectModal, e, action) => {
    setConfirmAction(action)
    setSelectedZoneModal(selectModal)

    if (selectModal === 'confirm') {
      if (action === 'manage') {
        setModalMessage('Are you sure you want to set this zone to unmanaged?')
        setSelectionManaged(e.target.value)
        if (e.target.value === 'true') {
          handleManaged(e.target.value)
        }
      }

      if (action === 'cancel-override') {
        setModalMessage('Are you sure you want to cancel all overrides for this zone?')
      }
    }
  }

  const renderModal = () => {
    switch(selectedZoneModal) {
      case 'override':
        return <ComfortModalRemoteOverride
                zoneData={currentZoneData}
                showMobile={showMobile}
                clearModal={clearModal}
                openScheduleModal={openScheduleModal}
                setProcessing={setProcessing}
                setRemoteSave={setRemoteSave}
                updateThermalZoneData={updateZoneData}
               />
      case 'event':
        return <ComfortModalScheduleEvent
                zoneData={currentZoneData}
                showMobile={showMobile}
                clearModal={clearModal}
                setProcessing={setProcessing}
                updateThermalZoneData={updateZoneData}
                setErrorMessage={setErrorMessage}
               />
      case 'confirm':
        return <ConfirmModal 
                modalMessage={modalMessage}
                modalAction={confirmAction === 'manage' ? 
                  () => handleManaged(selectionManaged) : 
                  () => handleCancelOverride()
                }
                clearModal={clearModal}
               />
      case 'loading':
        return <Loading message='Processing request' />
      default:
        return false
    }
  }

  const handleOpenSchedule = () => {
    setCurrentScheduleId(currentZoneData.scheduleId)
    sessionStorage.setItem('scheduleId', currentZoneData.scheduleId)
  }

  useEffect(() => {
    if (sessionStorage.scheduleId) {
      window.location.replace(`/schedules/${currentCampusId}`)
    }
  })

  const handleLock = (lockState) => {
    let newLock
    newLock = lockState === "on" ? true : false

    openZoneModal('loading')

    const generateAuthenticatedHeader = (addPostHeader) => {
      const headerObj = authentication.generateHeaderObject()
      const authenticatedHeaders = new Headers(headerObj)
      if (addPostHeader) {
        authenticatedHeaders.set('Content-Type', 'application/x-www-form-urlencoded')
      }
      return authenticatedHeaders
    }

    setCurrentLock(newLock)
  
    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + currentZoneData.thermalZoneEntityKey
    + '/thermalZone/lockLocalOverride', 
    {
      headers: generateAuthenticatedHeader(true),
      method: 'POST', 
      body: `lock=${newLock}`
    })
  
    .then(async response => {
      const res = await response.json()
      
      if (!response.ok) {
        setErrorMessage(() => res.message);

        clearModal();

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)

        throw new Error(res.message)
      } else {
        if (res) {
          updateZoneData()
        }

        return res
      }
    })

    .then(() => {
      setTimeout(() => {
        clearModal()
      }, 5000)
    })

    .catch(error => {
      clearModal();

      setTimeout(() => {
        if (errorMessage === null && errorMessageRef.current === null) {
          setErrorMessage('Something went wrong. Try again or contact customer support.');
  
          setTimeout(() => {
            setErrorMessage(null)
          }, 9000)
        }
      }, 1000)

      console.error(error)
    })
  }

  const handleSchedule = (newSchedule) => {
    openZoneModal('loading')

    const generateAuthenticatedHeader = (addPostHeader) => {
      const headerObj = authentication.generateHeaderObject()
      const authenticatedHeaders = new Headers(headerObj)
      if (addPostHeader) {
        authenticatedHeaders.set('Content-Type', 'application/x-www-form-urlencoded')
      }
      return authenticatedHeaders
    }

    setCurrentSchedule(newSchedule)

    let newScheduleName = encodeURIComponent(newSchedule)
  
    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + currentZoneData.thermalZoneEntityKey
    + '/thermalZone/configuration', 
    {
      headers: generateAuthenticatedHeader(true),
      method: 'POST', 
      body: `scheduleName=${newScheduleName}`
    })
  
    .then(async response => {
      const res = await response.json()
      if (!response.ok) {
        setErrorMessage(res.message);

        clearModal();

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)

        throw new Error(res.message)
      } else {
        if (res) {
          updateZoneData()
        }

        return res
      }
    })

    .then(() => {
      setTimeout(() => {
        clearModal()
      }, 5000)
    })

    .catch(error => {
      clearModal();

      setTimeout(() => {
        if (errorMessage === null && errorMessageRef.current === null) {
          setErrorMessage('Something went wrong. Try again or contact customer support.');
  
          setTimeout(() => {
            setErrorMessage(null)
          }, 9000)
        }
      }, 1000)

      console.error(error)
    })
  }

  const handleManaged = (e) => {
    clearModal()

    openZoneModal('loading')
    let newManaged

    if (e === 'true') {
      newManaged = 'normal'
      setZoneSettingsClass('')
    } else {
      newManaged = 'uncontrolled'
      setZoneSettingsClass('unmanaged')
    }

    newManaged = encodeURIComponent(newManaged)

    const generateAuthenticatedHeader = (addPostHeader) => {
      const headerObj = authentication.generateHeaderObject()
      const authenticatedHeaders = new Headers(headerObj)
      if (addPostHeader) {
        authenticatedHeaders.set('Content-Type', 'application/x-www-form-urlencoded')
      }
      return authenticatedHeaders
    }

    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + currentZoneData.thermalZoneEntityKey
    + '/thermalZone/configuration',  
    {
      headers: generateAuthenticatedHeader(true),
      method: 'POST', 
      body: `operationsFlag=${newManaged}`
    })
  
    .then(async response => {
      const res = await response.json()
      if (!response.ok) {
        clearModal();

        setErrorMessage(res.message);

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)

        throw new Error(res.message)
      } else {
        if (res) {
          updateZoneData()
        }

        return res
      }
    })

    .then(() => {
      setTimeout(() => {
        clearModal()
      }, 5000)
    })

    .catch(error => {
      clearModal();

      setTimeout(() => {
        if (errorMessage === null && errorMessageRef.current === null) {
          setErrorMessage('Something went wrong. Try again or contact customer support.');
  
          setTimeout(() => {
            setErrorMessage(null)
          }, 9000)
        }
      }, 1000)

      console.error(error)
    })
  }

  const handleCancelOverride = () => {
    openZoneModal('loading')

    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + currentZoneData.thermalZoneEntityKey
    + '/thermalZone/override/cancel',
    {
      headers: authenticatedComponent.generateAuthenticatedHeader(),
    })
  
    .then(async response => {
      const res = await response.json()
      if (!response.ok) {
        clearModal();

        setErrorMessage(res.message);

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)

        throw new Error(res.message)
      } else {
        if (res) {
          updateZoneData()
        }

        return res
      }
    })

    .then(() => {
      setTimeout(() => {
        clearModal()
      }, 5000)
    })

    .catch(error => {
      clearModal();

      setTimeout(() => {
        if (errorMessage === null && errorMessageRef.current === null) {
          setErrorMessage('Something went wrong. Try again or contact customer support.');
  
          setTimeout(() => {
            setErrorMessage(null)
          }, 9000)
        }
      }, 1000)

      console.error(error)
    })
  }
  
  const modal = renderModal();

  return (
    <ZoneSection>
      {modal}

      {processing && (<Loading message="Processing request"/>)}

      {errorMessage && (
        <div className="zone-message" ref={errorMessageRef}>
          <Message displayMessage={errorMessage} type='error' />
        </div>
      )}

      {currentZoneData && (
        <div className={showMobile ? "comfort-zone-settings" : "comfort-zone-settings desktop"}>
          <div className={`zone-card-container ${zoneHealth}`}>
            <table className="zone-status-card">
              <tbody>
                <ZoneCard
                  zoneRealtimeData={currentZoneData}
                  setZoneHealth={setZoneHealth}
                />
              </tbody>
            </table>
          </div>

          <div className={processing ? 'zone-settings-container processing' : 'zone-settings-container'}>
            <div className="zone-overrides">
              <h3>Zone Overrides</h3>
              <div className="remote-override">
                <p className="title">Remote Override</p>

                {remoteOverride ? (<>
                  <div className="active-override">
                    <p>Until {currentZoneData.overrideEnds}</p>

                    <div
                      className="cancel-overrides-button"
                      onClick={(e) => openZoneModal('confirm', e, 'cancel-override')}
                    >
                      Cancel Override
                    </div>
                  </div>
                  
                </>) 
                : 
                (<>
                  <div className="override-button" onClick={(e) => openZoneModal('override', e, 'override')}>
                    Set Override
                  </div>
                </>)}
              </div>

              <div className="local-override">
                <p className="title">Local Override</p>
                  {localOverride ? (<>
                    <p>Until {currentZoneData.localOverrideEnds}</p>
                  </>) 
                  : 
                  (<>
                    <p>None</p>
                  </>)}
              </div>
            </div>

            <div className="zone-schedules">
              <h3>Zone Schedules</h3>
              <div className="zone-schedule">
                <p className="title">Current Schedule</p>

                {currentZoneData &&
                  <select 
                    name="schedule"
                    onChange={(e) => handleSchedule(e.target.value)}
                    value={currentSchedule}
                  >
                    {
                      currentScheduleData.map((schedule) => (
                        <option 
                        key={`${schedule.scheduleName}-schedule-select`}
                        value={schedule.scheduleName}
                        >
                          {schedule.scheduleName}
                        </option>
                      ))
                    }

                    {currentZoneData.scheduleName === null && (<>
                      <option value="" disabled>
                        No Schedule
                      </option> 
                    </>)}
                  </select>
                }
              </div>

              <div className="zone-event">
                <p className="title">Event</p>

                {hasEvent ? (<>
                  <div className="active-override">
                    <p>Until {currentZoneData.eventEnds}</p>
                  </div>
                  
                </>) 
                : 
                (<>
                  <div className="event-button" onClick={(e) => openZoneModal('event', e, 'event')}>
                    Schedule Event
                  </div>
                </>)}
              </div>
            </div>

            <div className="zone-controls">
              <h3>Zone Controls</h3>

              {currentZoneData && (<>
                <div className="keypad">
                  <p className="title">Keypad {currentLock === true ? "Locked" : "Unlocked"}</p>

                  <label htmlFor="toggle-lock" className="switch">
                    <input 
                      type="checkbox" 
                      id="toggle-lock" 
                      className="checkbox" 
                      checked={currentLock === true ? true : false}
                      onChange={(e) => handleLock(e.target.value)} 
                    />
                    <span className="slider"></span>
                  </label>
                  
                </div>

                <div className="managed">
                  <p className="title">Managed</p>

                  <label htmlFor="toggle-managed" className="switch">
                    <input 
                      type="checkbox" 
                      id="toggle-managed" 
                      className="checkbox" 
                      checked={currentManaged === true ? true : false}
                      onChange={(e) => openZoneModal('confirm', e, 'manage')} 
                    />
                    <span className="slider"></span>
                  </label>
                  
                </div>
              </>)
              }
            </div>
          </div>
            
          <div className="zone-chart-container desktop">
            <div className="chart">
              <div className="chart-nav">
                <div className="tabs">
                  <div
                    className={chartTabActive === 'live' ? "live active" : "live"}
                    onClick={() => setChartTabActive('live')}
                  >
                    Live
                  </div>
                  <div
                    className={chartTabActive === 'history' ? "history active" : "history"}
                    onClick={() => setChartTabActive('history')}
                  >
                    History
                  </div>
                </div>

                {chartTabActive === 'history' && (
                  <div 
                    className="refresh"
                    onClick={() => handleRefresh()}
                  >
                    Refresh Graph
                  </div>
                )}
              </div>

              <div className="active-chart-container">
                <div
                  id="historyPerformanceGraph"
                  className={chartTabActive === "history" ? "performanceGraph" : "hidden performanceGraph"}
                  ref={historyChartRef}
                ></div>
                <div
                  id="livePerformanceGraph"
                  className={chartTabActive === "live" ? "performanceGraph" : "hidden performanceGraph"}
                  ref={liveChartRef}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )} 
    </ZoneSection>
  )
}

const ZoneSection = styled.section`
  .comfort-zone-settings {
    display: flex;
    flex-flow: column;
    row-gap: 24px;

    .zone-card-container {
      //ZONE STATUS
      table.zone-status-card {
        width: 100%;

        tr {
          align-items: center;
          display: flex;
          justify-content: space-between;
          /* margin: 12px; */
          padding: 4px 12px 4px 0;
          width: auto;
        }
      }

      &.healthy {
        table.zone-status-card tr { 
          background: rgba(0, 220, 146, 0.1);
        }
      }

      &.warn {
        table.zone-status-card tr { 
          background:  rgba(250, 151, 79, 0.1);
        }
      }

      &.unhealthy {
        table.zone-status-card tr { 
          background: rgba(249, 97, 56, 0.1);
        }
      }

      &.offline {
        table.zone-status-card tr { 
          background:  rgba(237, 241, 242, 0.1);
        }
      }

      &.unmanaged {
        table.zone-status-card tr { 
          background: rgba(146, 165, 168, 0.1);
        }
      }
    }

    .zone-chart-container {
      background: white;
      border-radius: 12px;
      box-shadow: 0 0 10px rgba(237, 241, 242, 0.5);
      margin-bottom: 24px;
      padding: 12px;

      //GRAPHS
      .chart {
        height: 100%;

        .active-chart-container {
          .performanceGraph {
            height: 600px;
          }

          .hidden {
            display: none;
            height: 0;
          }
        }
      }
    }

    //ZONE SETTINGS
    .zone-settings-container {
      column-gap: 24px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      h3 {
        font-size: 18px;
        margin: 0 0 8px;
      }

      .zone-overrides,
      .zone-schedules,
      .zone-controls {
        background: white;
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(237, 241, 242, 0.5);
        display: flex;
        flex-direction: column;
        font-size: 14px;
        justify-content: space-between;
        padding: 12px;
        width: 100%;

        p.title {
          font-weight: 600;
        }
      }

      select option:disabled {
        opacity: 0.5;
      }

      .zone-controls {
        .switch {
          display: inline-block;
          height: 20px;
          position: relative;
          width: 36px;
        }

        .switch input {
          height: 0;
          opacity: 0;
          width: 0;
        }

        .slider {
          -webkit-transition: .4s;
          background-color: #ccc;
          border-radius: 34px;
          bottom: 0;
          cursor: pointer;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: .4s;
        }

        .slider:before {
          -webkit-transition: .4s;
          background-color: white;
          border-radius: 50%;
          bottom: 2px;
          content: "";
          height: 16px;
          left: 2px;
          position: absolute;
          transition: .4s;
          width: 16px;
        }

        input:checked + .slider {
          background-color: #324E55;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #324E55;
        }

        input:checked + .slider:before {
          -ms-transform: translateX(16px);
          -webkit-transform: translateX(16px);
          transform: translateX(16px);
        }
      }

      .remote-override,
      .local-override,
      .zone-schedule,
      .zone-event,
      .keypad,
      .managed {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      .remote-override {
        .active-override p {
          margin: 0;
        }
      }

      .cancel-overrides-button,
      .override-button,
      .event-button {
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .comfort-zone-settings {
      row-gap: 12px;
      .zone-settings-container {
        flex-flow: row wrap;
        row-gap: 12px;

        h3 {
          font-size: 16px;
        }
      }
    }
  }
`

export default ZoneSettings