import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import authentication from '../../utilities/Authentication';
import configuration from '../../config';

import Modal from '../Modal';
import TempModeDefaults from './TempModeDefaults';
import ScheduleEventName from './ScheduleEventName';
import ScheduleDays from './ScheduleDays';
import StartEndTime from './StartEndTime';
import LowerUpperMode from './LowerUpperMode';

import icon_schedule from '../../assets/icon_schedule.svg';
import icon_plus from '../../assets/icon_plus.svg';
import AuthenticatedComponent from '../../utilities/AuthenticatedComponent';

const CreateScheduleModal = ({
  modalTitle,
  clearModal,
  showMobile,
  pageName,
  currentCampusId,
  setProcessingList,
  allScheduleCallback,
  setErrorMessage,
}) => {
  const authenticatedComponent = new AuthenticatedComponent
  const newDate = new Date()
  let year = newDate.toLocaleString("default", { year: "numeric" });
  let month = newDate.toLocaleString("default", { month: "2-digit" });
  let day = newDate.toLocaleString("default", { day: "2-digit" });
  let formattedDate = year + "-" + month + "-" + day;
  
  const defaultBlockCooling = false
  const defaultBlockHeating = false
  const defaultLowerBound = 55
  const defaultUpperBound = 95

  const [scheduleName, setScheduleName] = useState(`New Schedule: ${formattedDate}`)
  const [startDate, setStartDate] = useState(newDate)
  const [mainForm, setMainForm] = useState({
    scheduleName: scheduleName,
    scheduleId: null,
    defaultInstruction: {
      blockCooling: defaultBlockCooling,
      blockHeating: defaultBlockHeating,
      lowerBound: defaultLowerBound,
      upperBound: defaultUpperBound
    },
    daySchedules: null,
    dayScheduleExceptions: null,
  })
  const [formValues, setFormValues] = useState([{
    entries: [{
      fromHour: 6,
      fromMinute: 0,
      toHour: 14,
      toMinute: 30,
      instruction: {
        blockCooling: false,
        blockHeating: false,
        lowerBound: 68,
        upperBound: 75
      }
    }],
    narrowingDurationInMinutes: 180,
    wideningDurationInMinutes: 0,
    daySelectors: []
  }])
  const noDaysSelected = mainForm.daySchedules !== null && mainForm.daySchedules[0].daySelectors?.length === 0

  const handleRemove = (e, index) => {
    let item = e.currentTarget.parentNode.parentNode

    formValues.splice(index, 1)
    item.remove()
  }

  const handleAdd = () => {
    setFormValues([...formValues, {
      entries: [{
        fromHour: 6,
        fromMinute: 0,
        toHour: 14,
        toMinute: 30,
        instruction: {
          blockCooling: false,
          blockHeating: false,
          lowerBound: 68,
          upperBound: 75
        }
      }],
      narrowingDurationInMinutes: 180,
      wideningDurationInMinutes: 0,
      daySelectors: []
    }])
  }

  const handleName = (name) => {
    setScheduleName(name)
  }

  const handleChange = (index, name, value) => {
    let newFormValues = [...formValues]

    //default lowerbound upperbound mode
    if (name === 'defaultLowerBound') {
      setMainForm((mainForm) => (
        {...mainForm, 
          defaultInstruction: {
            ...mainForm.defaultInstruction,
            lowerBound: value
          }
        }
      ))
    } else if (name === 'defaultUpperBound') {
      setMainForm((mainForm) => (
        {...mainForm, 
          defaultInstruction: {
            ...mainForm.defaultInstruction,
            upperBound: value
          }
        }
      ))
    } else if (name === 'defaultMode') {
      if (value === 'heat') {
        setMainForm((mainForm) => (
          {...mainForm, 
            defaultInstruction: {
              ...mainForm.defaultInstruction,
              blockCooling: true,
              blockHeating: false
            }
          }
        ))
      } else if (value === 'cool') {
        setMainForm((mainForm) => (
          {...mainForm, 
            defaultInstruction: {
              ...mainForm.defaultInstruction,
              blockCooling: false,
              blockHeating: true
            }
          }
        ))
      } else if (value === 'fan') {
        setMainForm((mainForm) => (
          {...mainForm, 
            defaultInstruction: {
              ...mainForm.defaultInstruction,
              blockCooling: true,
              blockHeating: true
            }
          }
        ))
      } else if (value === 'auto') {
        setMainForm((mainForm) => (
          {...mainForm, 
            defaultInstruction: {
              ...mainForm.defaultInstruction,
              blockCooling: false,
              blockHeating: false
            }
          }
        ))
      }
    }

    // lowerbound upperbound mode
    if (name === 'lowerBound') {
      newFormValues[index].entries[0].instruction.lowerBound = value
    } else if (name === 'upperBound') {
      newFormValues[index].entries[0].instruction.upperBound = value
    } else if (name === 'heat') {
      newFormValues[index].entries[0].instruction.blockCooling = true
      newFormValues[index].entries[0].instruction.blockHeating = false
    } else if (name === 'cool') {
      newFormValues[index].entries[0].instruction.blockCooling = false
      newFormValues[index].entries[0].instruction.blockHeating = true
    } else if (name === 'fan') {
      newFormValues[index].entries[0].instruction.blockCooling = true
      newFormValues[index].entries[0].instruction.blockHeating = true
    } else if (name === 'auto') {
      newFormValues[index].entries[0].instruction.blockCooling = false
      newFormValues[index].entries[0].instruction.blockHeating = false
    }

    //start end time
    if (name === 'startTime' || name === 'endTime') {
      let newHour = value.split(":")[0]
      let newMin = value.substring(value.indexOf(':') + 1)
      
      if (newHour.startsWith(0)) {
        newHour = newHour.slice(1)
      } else if (newMin.startsWith(0)) {
        newMin = newMin.slice(1)
      }
  
      if (name === 'startTime') {
        newFormValues[index].entries[0].fromHour = parseInt(newHour)
        newFormValues[index].entries[0].fromMinute = parseInt(newMin)
      } else {
        newFormValues[index].entries[0].toHour = parseInt(newHour)
        newFormValues[index].entries[0].toMinute = parseInt(newMin)
      }
    }

    setFormValues(newFormValues)

    //day selectors
    if (name === 'scheduleDays') {
      newFormValues[index].daySelectors = value[0]
      
      setMainForm((mainForm) => ({...mainForm, daySchedules: newFormValues}))
    } else if (name === 'scheduleDaysException') {

        newFormValues[index].daySelectors = value[0]
        newFormValues[index].fromDay = value[1].fromDay
        newFormValues[index].fromMonth = value[1].fromMonth
        newFormValues[index].toDay = value[1].toDay
        newFormValues[index].toMonth = value[1].toMonth
        setMainForm((mainForm) => ({...mainForm, dayScheduleExceptions: newFormValues}))
    }
  }

  const saveCreateSchedule = () => {
    let newDaySchedules = []
    let newDayScheduleExceptions = []

    //filter daySchedules
    if (mainForm.daySchedules) {
      mainForm.daySchedules.map((schedule) => {
        if (!schedule.fromDay) {
          newDaySchedules.push(schedule)
        }
      })
    }
    //filter dayScheduleExceptions
    if (mainForm.dayScheduleExceptions) {
      mainForm.dayScheduleExceptions.map((schedule) => {
        if (schedule.fromDay) {
          newDayScheduleExceptions.push(schedule)
        }
      })
    }

    setMainForm(mainForm.daySchedules = newDaySchedules)
    setMainForm(mainForm.dayScheduleExceptions = newDayScheduleExceptions)
    setMainForm(mainForm.scheduleName = scheduleName)

    // Send data to the backend via POST
    setProcessingList(true)
    fetch(configuration['backend_host'] + '/ahiapi/'
    + currentCampusId
    + '/schedule', 
    {
      headers: authenticatedComponent.generateAuthenticatedHeader(true),
      method: 'POST', 
      body: JSON.stringify(mainForm)
    })
  
    .then(async response => {
      if (!response.ok) {
        const res = await response.json()

        setErrorMessage(res.message)

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)
        throw new Error(res.message)
      } else {
        setProcessingList(false)
        return response.json()
      }
    })

    .then(() => {
      authenticatedComponent.getAllScheduleData(currentCampusId, 'large', allScheduleCallback)
    })

    .catch(error => {
      setProcessingList(false)
      console.error(error)
    })
  }

  return (
    <Modal
      modalTitle={modalTitle}
      modalImage={icon_schedule}
      clearModal={clearModal}
      updateSettings={saveCreateSchedule}
      shouldDisable={noDaysSelected}
      showMobile={showMobile}
      renderModalContent={(<>
        <TempModeDefaults
          defaultBlockCooling={defaultBlockCooling}
          defaultBlockHeating={defaultBlockHeating}
          defaultLowerBound={defaultLowerBound}
          defaultUpperBound={defaultUpperBound}
          handleChange={handleChange}
        />
        <ScheduleEventName 
          pageName={pageName}
          handleName={handleName}
          scheduleName={scheduleName}
        />

        <ScheduleList className="schedule-list-container">
          {formValues.map((element, index) => (
            <CreateSchedule className="create-schedule-container" key={index}>
              <div className="create-schedule-settings">
                <ScheduleDays
                  handleChange={handleChange}
                  index={index}
                />
                <StartEndTime 
                  handleChange={handleChange}
                  index={index}
                />
        
                <div className="end-container">
                  <LowerUpperMode 
                    handleChange={handleChange}
                    index={index}
                  />
        
                  {index !== 0 && (
                    <div 
                    className="remove"
                    onClick={(e) => handleRemove(e, index)}
                    >
                      Remove
                    </div>
                  )}
                </div>
              </div>
            </CreateSchedule>
          ))}
        </ScheduleList>
        
        <AddSchedule className="add-schedule">
          <div 
            className="add-button-container"
            onClick={() => handleAdd()}
          >
            <img
              src={icon_plus}
              alt="button to add another schedule"
              height={18}
              width={18}
            />
          </div>
        </AddSchedule>
      </>)}
    /> 
  )
}

const CreateSchedule = styled.div`
  .create-schedule-settings {
    border-bottom: 2px solid #EAECF0;
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    width: 100%;

    & > div:nth-of-type(1) {
      width: 35%
    }

    & > div:nth-of-type(2) {
      width: 25%
    }

    & > div:nth-of-type(3) {
      width: 30%
    }

    .end-container {
      align-items: flex-end;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      .remove {
        cursor: pointer;
        font-size: 12px;
        text-decoration: underline;
      }
    }
  }
`

const AddSchedule = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;

  .add-button-container {
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 25px;
    justify-content: center;
    transition: ease-in-out all 250ms;
    width: 25px;
    &:hover {
      background: #EAECF0;
      transition: ease-in-out all 250ms;
    }
  }
`

const ScheduleList = styled.div`
  // overflow: hidden;
  // overflow-y: auto;
`

export default CreateScheduleModal