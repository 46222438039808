import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import icon_chevron from '../../assets/icon_chevron.svg';
import MonthDayPicker from './MonthDayPicker';

const ScheduleDays = ({
    handleChange,
    index,
    selectedDays,
    isException,
    selectedFromDay,
    selectedFromMonth,
    selectedToDay,
    selectedToMonth,
}) => {
    const [activeDayFilter, setActiveDayFilter] = useState([])
    const [activeGroupFilter, setActiveGroupFilter] = useState([])
    const [shouldExpand, setShouldExpand] = useState(['Weekends', 'Weekdays'])
    const [showDateRange, setShowDateRange] = useState(false)
    const [exceptionValues, setExceptionValues] = useState()

    const CATEGORIES = [
        {
            name: 'Weekdays',
            value: 'Weekdays',
            days: [
                {
                    name: 'Monday',
                    value: 'Monday',
                    parent: 'Weekdays'
                },
                {
                    name: 'Tuesday',
                    value: 'Tuesday',
                    parent: 'Weekdays'
                },
                {
                    name: 'Wednesday',
                    value: 'Wednesday',
                    parent: 'Weekdays'
                },
                {
                    name: 'Thursday',
                    value: 'Thursday',
                    parent: 'Weekdays'
                },
                {
                    name: 'Friday',
                    value: 'Friday',
                    parent: 'Weekdays'
                }
            ]      
        },
        {
            name: 'Weekends',
            value: 'Weekends',
            days: [
                {
                    name: 'Saturday',
                    value: 'Saturday',
                    parent: 'Weekends'
                },
                {
                    name: 'Sunday',
                    value: 'Sunday',
                    parent: 'Weekends'
                },
            ]
        }
    ]

    let groupSelect = []
    let allDays = []
    let allWeekdays = ['Monday',  'Tuesday', 'Wednesday', 'Thursday', 'Friday']
    let allWeekends = ['Saturday', 'Sunday']
    let allSingleDays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ]
    let allGroups = [
        "Weekends",
        "Weekdays"
    ]
    let newDayFilter = [...activeDayFilter]

    const isSubset = (array1, array2) => {
        for (let el of array1) {
            if (!array2.includes(el)) {
                return false
            }
        }
        return true
    }

    useEffect(() => {
        //if Edit Modal, set days from schedule
        if (selectedDays !== undefined) {
            let newActiveDay = [...activeDayFilter]
            let newActiveGroup = [...activeGroupFilter]
    
            if (isSubset(selectedDays, allWeekdays)) {
                selectedDays.map((day) => {
                    newActiveDay.push(day)
                    setActiveDayFilter(newActiveDay)
                })
            }

            if (isSubset(selectedDays, allWeekends)) {
                selectedDays.map((day) => {
                    newActiveDay.push(day)
                    setActiveDayFilter(newActiveDay)
                })
            }

            if (selectedDays[0] === 'Weekdays') {
                newActiveGroup.push("Weekdays")
                setActiveGroupFilter(newActiveGroup)

                allWeekdays.map((day) => {
                    newActiveDay.push(day)
                    setActiveDayFilter(newActiveDay)
                })
            }
    
            if (selectedDays[0] === 'Weekends') {
                newActiveGroup.push("Weekends")
                setActiveGroupFilter(newActiveGroup)

                allWeekends.map((day) => {
                    newActiveDay.push(day)
                    setActiveDayFilter(newActiveDay)
                })
            }
            
            if (selectedDays[0] === 'All') {
                allGroups.map((group) => {
                    newActiveGroup.push(group)
                    setActiveGroupFilter(newActiveGroup)
                })
                allSingleDays.map((day) => {
                    newActiveDay.push(day)
                    setActiveDayFilter(newActiveDay)
                })
            }
        }

        if (isException) {
            let newExceptionValues = {
                fromDay: selectedFromDay,
                toDay: selectedToDay,
                fromMonth: selectedFromMonth,
                toMonth: selectedToMonth,
            }
            setShowDateRange(true)
            // setExceptionValues(newExceptionValues)
        }
    }, [])
    

    const handleGroupSelect = (category) => {
        if (activeGroupFilter.includes(category.value)) {
            //remove group from activeGroupFilter
            const filterGroupIndex = activeGroupFilter.indexOf(category.value)
            const newGroupFilter = [...activeGroupFilter]
            newGroupFilter.splice(filterGroupIndex, 1)
            setActiveGroupFilter(newGroupFilter)

            //get all days for each category
            for (let i = 0; i < category.days.length; i++) {
                allDays.push(category.days[i].value)
            }
            //remove days on deselect group day
            newDayFilter = newDayFilter.filter((el) => !allDays.includes(el))
            setActiveDayFilter(newDayFilter)
            
        } else {
            setActiveGroupFilter([...activeGroupFilter, category.value])

            //if day not already in array, add it
            category.days.map((day) => {
                if (!activeDayFilter.includes(day.value)) {
                    groupSelect.push(day.value)
                    setActiveDayFilter(activeDayFilter.concat(groupSelect))
                }
            })
        }
    }

    const handleDaySelect = (day) => {
        if (activeDayFilter.includes(day.value)) {
          const dayIndex = activeDayFilter.indexOf(day.value)
          const newDay = [...activeDayFilter]
          newDay.splice(dayIndex, 1)
          setActiveDayFilter(newDay)

          //if group is selected, deselect
          if (activeGroupFilter.includes(day.parent)) {
            const filterGroupIndex = activeGroupFilter.indexOf(day.parent)
            const newGroupFilter = [...activeGroupFilter]
            newGroupFilter.splice(filterGroupIndex, 1)
            setActiveGroupFilter(newGroupFilter)
          }
        } else {
            //if day not already in array, add it
            if (!activeDayFilter.includes(day.value)) {
            setActiveDayFilter([...activeDayFilter, day.value])
            }
        }
    }

    const handleExpand = (category) => {
        if (shouldExpand.includes(category.value)) {
            const filterIndex = shouldExpand.indexOf(category.value)
            const newFilter = [...shouldExpand]
            newFilter.splice(filterIndex, 1)
            setShouldExpand(newFilter)
        } else {
        setShouldExpand([...shouldExpand, category.value])
        }
    }

    const handleDateRange = () => {
        setShowDateRange(!showDateRange)
    }

    let activeArray = []

    const setActiveArray = () => {
        if (activeGroupFilter.length === 2) {
            activeArray = ["All"]
        } else {
            if (activeGroupFilter.length === 1) {
                if (activeGroupFilter[0] === 'Weekdays') {
                    activeArray = [...activeDayFilter, (activeGroupFilter[0])]
                    //if weekdays checked, remove individual weekday days from array
                    activeArray = activeArray.filter((el) => !allWeekdays.includes(el))
                    
                } else {
                    activeArray = [...activeDayFilter, (activeGroupFilter[0])]
                    //if weekends checked, remove individual weekend days from array
                    activeArray = activeArray.filter((el) => !allWeekends.includes(el))
                }
            }

            if (activeGroupFilter.length === 0) {
                for (let i = 0; i < activeDayFilter.length; i++) {
                    activeArray = [...activeArray, activeDayFilter[i]]
                }
            }
        }
    }

    const handleDayPicker = ( value ) => {
        setExceptionValues(value)
        setActiveArray()
        let exceptionArray = [activeArray, value]
        handleChange(index, 'scheduleDaysException', exceptionArray)
    }

    useEffect(() => {
        //if all weekdays marked manually, add group to activeGroupFilter
        if (isSubset(allWeekdays, activeDayFilter)) {
            if (!activeGroupFilter.includes('Weekdays')) {
                setActiveGroupFilter([...activeGroupFilter, 'Weekdays'])
            }
        }

        //if all weekends marked manually, add group to activeGroupFilter
        if (isSubset(allWeekends, activeDayFilter)) {
            if (!activeGroupFilter.includes('Weekends')) {
                setActiveGroupFilter([...activeGroupFilter, 'Weekends'])
            }
        }

        setActiveArray()

        if (showDateRange) {
            let exceptionArray = [activeArray, exceptionValues]
            handleChange(index, 'scheduleDaysException', exceptionArray)
        } else {
            handleChange(index, 'scheduleDays', [activeArray])
        }
    }, [activeDayFilter, activeGroupFilter])

    const DayFilters = ({ categories }) => {
        return (<>
            <div className="days-container">
                <div className="title">Days - <span>Choose one or more</span></div>

                <div className="day-items">
                    {categories.map((category) => (
                    <div 
                        className={
                            shouldExpand.includes(category.value) ? 
                            `${category.value}-group-items` 
                            : 
                            `${category.value}-group-items hidden`
                        } 
                        key={category.value}
                    >
                        <div className="group-container">
                            <div className="group-label">
                                <label className="group-item list-item">
                                <input 
                                    type="checkbox"
                                    checked={activeGroupFilter.includes(category.value)}
                                    onChange={() => handleGroupSelect(category)}
                                    value={category.value}
                                />
                                <span className="day-checkmark"></span>

                                {category.name}
                                </label>
                            </div>

                            <div 
                                className={"expand"}
                                onClick={() => handleExpand(category)}
                            >
                                <img 
                                    src={icon_chevron}
                                    alt="arrow to show or hide sub items for weekends or weekdays"
                                />
                            </div>
                        </div>
                    
                        {`${category.value}Options` && category.days.map((day) => (
                            <label className="day-item list-item" key={day.value}>
                                <input 
                                    type="checkbox"
                                    checked={activeDayFilter.includes(day.value)}
                                    onChange={() => handleDaySelect(day)}
                                    value={day.value}
                                />
                                <span className="day-checkmark"></span>

                                {day.name}
                            </label>
                        ))}
                    </div>
                    ))}
                </div>
            </div>
        </>)
    }

    return (
        <DayPicker className="day-settings">
            <div className="schedule-days-container">
                <DayFilters categories={CATEGORIES} />
            </div>

            {(index !== 0 || isException) && (
                <div className="date-range">
                    <label className="group-item list-item">
                        <input 
                            type="checkbox"
                            checked={showDateRange}
                            onChange={() => handleDateRange()}
                            value={showDateRange}
                        />
                        <span className="day-checkmark"></span>
                        Date Range
                    </label>

                    {showDateRange && (
                        <MonthDayPicker
                            handleDayPicker={handleDayPicker}
                            index={index}
                            selectedFromDay={selectedFromDay}
                            selectedFromMonth={selectedFromMonth}
                            selectedToDay={selectedToDay}
                            selectedToMonth={selectedToMonth}
                        />
                    )}
                </div>
            )}
            
        </DayPicker>
    )
}

const DayPicker = styled.div`
    .schedule-days-container{
        align-items: flex-start;
        border: 1px solid #EAECF0;
        border-radius: 8px;
        color: #02222B;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        overflow-y: auto;
        max-width: 628px;
        width: 100%;
    }

    .days-container {
        position: relative;
        width: 100%;

        .title {
            background: #F2F4F7;
            font-size: 12px;
            font-weight: 500;
            padding: 10px 14px;
            position: sticky;
            top: 0;
            z-index: 10;

            span {
                font-style: oblique;
            }
        }
    }

    .group-items {
        display: flex;
        flex-flow: column;
    }

    .group-container,
    .group-container {
        align-items: center;
        background: #F9FAFB;
        border-bottom: 1px solid #EAECF0;
        display: flex;
        justify-content: space-between;
    }

    .expand {
        padding-right: 12px;

        img {
            height: 16px;
            transition: ease-in-out all 250ms;
            width: 16px;
        }
    }

    .Weekdays-group-items.hidden,
    .Weekends-group-items.hidden {
        .expand img {
            transform: rotate(180deg);
        }

        .day-item {
            display: none;
        }
    }

    label.group-item,
    label.day-item {
        align-items: center;
        display: flex;
        font-weight: 600;
        padding: 10px 12px;
    }

    label.day-item {
        padding-left: 38px;
    }

    label.list-item, 
    .group-items {
        border-bottom: 1px solid #EAECF0;
    }

    label.list-item:last-child,
    .group-items:last-child {
        border-bottom: none;
    }

    .date-range label {
        border: none;
        line-height: 2;
        padding: 14px 0 0;
    }

    //Checklist styles
    .list-item input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
        
    .list-item .day-checkmark {
        align-items: center;
        background-color: #EAECF0;
        border: solid 1px #EAECF0;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        height: 16px;
        justify-content: center;
        margin-right: 10px;
        width: 16px;
    }
        
    .list-item:hover input ~ .day-checkmark {
        filter: brightness(0.85)
    }
        
    input:checked ~ .day-checkmark {
        background-color: #D1FADF;
        border: solid 1px #12B76A;
        border-radius: 4px;
    }
        
    .day-checkmark:after {
        content: "";
        position: unset;
        display: none;
    }
        
    input:checked ~ .day-checkmark:after {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        border: solid #12B76A;
        border-width: 0 3px 3px 0;
        display: block;
        height: 8px;
        margin-bottom: 4px;
        transform: rotate(45deg);
        width: 4px;
    }

`

export default ScheduleDays