import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

const MonthDayPicker = ({
    handleDayPicker,
    selectedFromDay,
    selectedFromMonth,
    selectedToDay,
    selectedToMonth,
}) => {
    const [startMonth, setStartMonth] = useState('01')
    const [endMonth, setEndMonth] = useState('01')
    const [startDay, setStartDay] = useState('01')
    const [endDay, setEndDay] = useState('01')
    const [startDayArray, setStartDayArray] = useState([])
    const [endDayArray, setEndDayArray] = useState([])
    const [dateRange, setDateRange] = useState({
        fromDay: 1,
        toDay: 1,
        fromMonth: 1,
        toMonth: 1,
    })

    useEffect(() => {
        if (selectedFromDay) {
            setDateRange({
                fromDay: selectedFromDay,
                toDay: selectedToDay,
                fromMonth: selectedFromMonth,
                toMonth: selectedToMonth,
            })
        }
    }, [])

    let monthArray = []
    let monthName
    let monthIndex
    let daysInMonth
    const convertedFromMonth = selectedFromMonth && selectedFromMonth.toString().padStart(2, '0')
    const convertedFromDay = selectedFromDay && selectedFromDay.toString().padStart(2, '0')
    const convertedToMonth = selectedToMonth && selectedToMonth.toString().padStart(2, '0')
    const convertedToDay = selectedToDay && selectedToDay.toString().padStart(2, '0')

    for (let i = 0; i < 12; i++) {
        monthIndex = [i] < 9 ? '0' + (i + 1) : (i + 1)
        monthName = new Date(null, i + 1, null).toLocaleDateString("en-US", {month: "long"})
        daysInMonth = new Date(null, i + 1, null).toLocaleDateString("en-US", {day: "numeric"})
        
        monthArray.push({monthName, monthIndex, daysInMonth})
    }

    useEffect(() => {
        const dayLength = Array(31).fill(1).map((n, i) => n + i)
        setStartDayArray(dayLength)
        setEndDayArray(dayLength)
    }, [])

    useEffect(() => {
        monthArray.map((month) => {
            if (month.monthIndex === startMonth) {
                const dayLength = Array(parseInt(month.daysInMonth)).fill(1).map((n, i) => n + i)
                setStartDayArray(dayLength)
            }
        })
    }, [startMonth])

    useEffect(() => {
        monthArray.map((month) => {
            if (month.monthIndex === endMonth) {
                const dayLength = Array(parseInt(month.daysInMonth)).fill(1).map((n, i) => n + i)
                setEndDayArray(dayLength)
            }
        })
    }, [endMonth])

    const handleDateRange = (value, name) => {
        let newDate = value
        if (newDate.startsWith(0)) {
            newDate = newDate.slice(1)
        }

        if (name === 'month-start') {
            setStartMonth(parseInt(newDate))
            setDateRange((dateRange) => ({...dateRange, fromMonth: parseInt(newDate)}))
        } else if (name === 'day-start') {
            setStartDay(parseInt(newDate))
            setDateRange((dateRange) => ({...dateRange, fromDay: parseInt(newDate)}))
        } else if (name === 'month-end') {
            setEndMonth(parseInt(newDate))
            setDateRange((dateRange) => ({...dateRange, toMonth: parseInt(newDate)}))
        } else if (name === 'day-end') {
            setEndDay(parseInt(newDate))
            setDateRange((dateRange) => ({...dateRange, toDay: parseInt(newDate)}))
        }
    }

    useEffect(() => {
        handleDayPicker(dateRange)
    }, [dateRange])

    return (
        <DayPicker className="date-picker-container">
            <div className="date-start">
                <div className="title">Start Day</div>
                <div className="start-day-container">
                    <div className="month-start">
                        <select
                            name="month-start"
                            id="month-start"
                            onChange={(e) => handleDateRange(e.target.value, 'month-start')}
                            defaultValue={selectedFromMonth ? 
                                convertedFromMonth
                                :
                                '01'
                            }
                        >
                            {monthArray.map((month) => (
                                <option 
                                    key={`start-${month.monthName}`} 
                                    value={month.monthIndex}
                                >
                                    {month.monthName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="day-start">
                        <select
                            name="day-start"
                            id="day-start"
                            onChange={(e) => handleDateRange(e.target.value, 'day-start')}
                            value={selectedFromDay ? 
                                convertedFromDay
                                :
                                startDay
                            }
                        >
                            {startDayArray.map((day) => (
                                <option
                                    key={`start-${day}-day`}
                                    value={day < 10 ? '0' + day : day}
                                >
                                    {day}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="date-end">
                <div className="title">End Day</div>
                <div className="end-day-container">
                    <div className="month-end">
                        <select
                            name="month-end"
                            id="month-end"
                            onChange={(e) => handleDateRange(e.target.value, 'month-end')}
                            defaultValue={selectedToMonth ? 
                                convertedToMonth
                                :
                                '01'
                            }
                        >
                            {monthArray.map((month) => (
                                <option
                                    key={`end-${month.monthName}`}
                                    value={month.monthIndex}
                                >
                                    {month.monthName
                                }</option>
                            ))}
                        </select>
                    </div>
                    <div className="day-end">
                        <select
                            name="day-end"
                            id="day-end"
                            onChange={(e) => handleDateRange(e.target.value, 'day-end')}
                            value={selectedToDay ? 
                                convertedToDay
                                :
                                endDay
                            }
                        >
                            {endDayArray.map((day) => (
                                <option
                                    key={`end-${day}-day`}
                                    value={day < 10 ? '0' + day : day}
                                >
                                    {day}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </DayPicker>
    )
}

const DayPicker = styled.div`
    display: flex;
    flex-flow: column;
    height: auto;
    justify-content: space-between;
    max-height: 170px;
    width: 100%;

    label {
        font-weight: 500;
    }
    
    .date-start .title,
    .date-end .title {
        background: white;
        font-size: 14px;
        padding: 10px 0;
    }

    .start-day-container,
    .end-day-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .month-end select, 
    .month-start select {
        width: 135px;
    }

    .day-end select, 
    .day-start select {
        width: 65px;
    }
`

export default MonthDayPicker