import configuration from '../config'
import auth0 from 'auth0-js'

class Authentication {
  constructor() {
    this.webAuth = new auth0.WebAuth({
      domain: configuration['auth0_domain'],
      clientID: configuration['auth0_clientid'],
      responseType: 'token id_token',
      scope: 'openid profile user_id email',
      redirectUri: configuration['auth0_callback'],
      audience: configuration['auth0_audience'],
      state: configuration['random_num'],
    });

    this.logoutLink = configuration['logout_link']

    this.authenticationData = {
      expiresAt: 0
    }

    if (localStorage.getItem('AuthenticationData')) {
      this.authenticationData = JSON.parse(localStorage.getItem('AuthenticationData'));
    }
  }

  login(e) {
    if (e) {
      e.preventDefault();
    }
    
    sessionStorage.clear()
    sessionStorage.setItem(configuration['random_num'], window.location.pathname)

    this.webAuth.authorize();
  }

  logout() {
    localStorage.removeItem('AuthenticationData');
    sessionStorage.clear()
    this.authenticationData = {
      expiresAt: 0
    }
  }

  handleAuthentication(postAuthenticationCallback) {
    if (window.location.hash.indexOf('access_token') > -1) {
      this.webAuth.parseHash(this.verifyAuthentication.bind(this, postAuthenticationCallback))
    } else if (this.authenticationData['expiresAt']) {
      if (window.location.hash.indexOf('access_token') === -1) {
        postAuthenticationCallback()
      }
    } else {
      if (!window.location.pathname.includes('lobbyKiosk')) {
        this.login();
      }
    }
  }

  verifyAuthentication(postAuthenticationCallback, err, authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      if (window.location.hash.indexOf('&') > -1 && window.location.hash !== '') {
        window.location.hash = '';
      }
      this.saveLoginInfo(authResult);
      postAuthenticationCallback()
    }
    else if (authResult) {
      this.webAuth.authorize();
    }
  }

  checkExpiration() {
    if (this.authenticationData['expiresAt'] && (new Date()).getTime() > this.authenticationData['expiresAt']) {
      this.logout()
      this.login()
    }
  }
  
  isLoggedIn() {
    return !!this.authenticationData['accessToken']
  }

  generateHeaderObject() {
    return {
      Authorization: 'Bearer ' + this.authenticationData['accessToken']
    }
  }

  saveLoginInfo(authResult) {
    this.authenticationData['expiresAt'] = JSON.stringify(
      ((new Date()).getTime() + authResult.expiresIn * 1000) - 10000
    );

    this.authenticationData['accessToken'] = authResult.accessToken;
    this.authenticationData['idToken'] = authResult.idToken;
    this.authenticationData['user_id'] = authResult['idTokenPayload']['sub'];
    this.authenticationData['email'] = authResult['idTokenPayload']['email'];
    this.authenticationData['name'] = authResult['idTokenPayload']['name'];
    this.authenticationData['state'] = this.webAuth.baseOptions.state;
    this.saveAuthenticationData()
  }

  saveAuthenticationData() {
    if (this.authenticationData) {
      localStorage.setItem('AuthenticationData', JSON.stringify(this.authenticationData));
    }
  }

  getUserId(){
    return this.authenticationData['user_id'].split('|')[1];
  }

  getEmailAddress() {
    return this.authenticationData['email']
  }

  getName() {
    return this.authenticationData['name']
  }

}

const authentication = new Authentication()
export default authentication;