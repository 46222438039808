import React, { useEffect } from 'react'
import styled from '@emotion/styled';

import processRealtimeData from '../utilities/ProcessRealtimeZoneHelper'

const ZoneCard = ({
  zoneRealtimeData,
  selectZone,
  setZoneHealth
 }) => {

  const formattedZoneRealtime = processRealtimeData(zoneRealtimeData)
  let hasTemperature = formattedZoneRealtime.currentTemperature !== 'N/A'

  useEffect(() => {
    setZoneHealth(formattedZoneRealtime.zoneStatusCSS)
  }, [zoneRealtimeData])

  return(<>
    <ZoneCardTr>
      <td className="location " onClick={selectZone}>
        <div className={"zone-health-indicator " + formattedZoneRealtime.zoneStatusCSS}></div>
        {zoneRealtimeData && zoneRealtimeData.longThermalZoneDisplayName}
      </td>
      
      <td className="status">
        <div className={"status " + formattedZoneRealtime.zoneStatusCSS}>
          {formattedZoneRealtime.zoneStatus}
        </div>
      </td>
      
      <td className="temp-range">
        {hasTemperature && (
          <div className="zone-thermostat-container">
            <p className="current">
              {formattedZoneRealtime.currentTemperature}°
            </p>
            
            <p className="range">
            {formattedZoneRealtime.temperatureLowerBound}° - {formattedZoneRealtime.temperatureUpperBound}°
            </p>
          </div>
        )}
      </td>
      
      <td className="schedule">
        {formattedZoneRealtime.scheduleOrOverride}
      </td>
      
      <td className="activity">
        {formattedZoneRealtime.activityState}
      </td>
      
      <td className="keypad">
        {formattedZoneRealtime.keypadLock}
      </td>
      
      <td className="managed">
        {formattedZoneRealtime.controlState}
      </td>
    </ZoneCardTr>
  </>)
}

const ZoneCardTr = styled.tr`
  .location {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    
    .zone-health-indicator {
      border-right: 0;
      border-top: 0;
      border-bottom: 0;
      height: 40px;
      margin-right: 12px;
      min-width: 5px;

      &.healthy {
        background-image: 
          linear-gradient(
            to bottom, 
            transparent 0%,
            transparent 10%,
            #00DC92 10%,
            #00DC92 90%,
            transparent 90%,
            transparent 100%
          );
      }

      &.warn {
        background-image: 
          linear-gradient(
            to bottom, 
            transparent 0%,
            transparent 10%,
            #FA974F 10%,
            #FA974F 90%,
            transparent 90%,
            transparent 100%
          );
      }

      &.unhealthy {
        background-image: 
          linear-gradient(
            to bottom, 
            transparent 0%,
            transparent 10%,
            #F96138 10%,
            #F96138 90%,
            transparent 90%,
            transparent 100%
          );
      }

      &.offline {
        background-image: 
          linear-gradient(
            to bottom, 
            transparent 0%,
            transparent 10%,
            #EDF1F2 10%,
            #EDF1F2 90%,
            transparent 90%,
            transparent 100%
          );
      }

      &.unmanaged {
        background-image: 
          linear-gradient(
            to bottom, 
            transparent 0%,
            transparent 10%,
            #92A5A8 10%,
            #92A5A8 90%,
            transparent 90%,
            transparent 100%
          );
      }
    }
  }

  .status {
    border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    padding: 4px;
    text-align: center;
    width: 110px;

    &.healthy {
      background: rgba(0, 220, 146, 0.3);
      border: 2px solid #00DC92;
      color: #006140;
    }

    &.warn {
      background: rgba(250, 151, 79, 0.3);
      border: 2px solid #FA974F;
      color: #863F0B;
    }

    &.unhealthy {
      background: rgba(249, 97, 56, 0.3);
      border: 2px solid #F96138;
      color: #7E230A;
    }

    &.offline {
      background: rgba(237, 241, 242, 0.3);
      border: 2px solid #EDF1F2;
    }

    &.unmanaged {
      background: rgba(146, 165, 168, 0.3);
      border: 2px solid #92A5A8;
    }
  }

  .temp-range .zone-thermostat-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100px;

    p {
      margin: 0;
    }

    .current {
      font-size: 18px;
      font-weight: 800;
      line-height: 20px;
    }

    .range {
      color: #62797E;
      font-size: 13px;
    }
  }

  .activity .activity-state {
    align-items: center;
    display: flex;

    img {
      padding-right: 6px;
    }
  }

  @media screen and (max-width: 768px) {
    .status,
    .managed,
    .schedule {
      display: none;
    }
  }
`

export default ZoneCard;
