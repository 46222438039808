import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
// import exportingModule from 'highcharts/modules/exporting'
import MomentTimeZone from 'moment-timezone'
import Moment from 'moment'
import authentication from './Authentication'

MomentTimeZone();
Moment();

window.moment = Moment;
require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)

var elexity = elexity || {};
elexity.admin = elexity.admin || {};
elexity.admin.monitor = elexity.admin.monitor || {};

elexity.admin.monitor.graphs = {
    entityKey : "",
    definitionUrl : "",
    liveUrl : "",
    
    liveDivName : "",
    liveHistorySubtitle: "",
    
    performanceLiveGraph : null,
    
    liveLoading : false,
    
    focusFromTimestamp : null,
    focusToTimestamp : null,
    
    lastLiveTimestamp : null,    
    
    graphDefinition : null,
    graphConfiguration : {},
    
    intervalHook : null,
    
    authToken : null,
    
    logAndload : async function(_step, url, callback) {
        // console.log(step + " : " + url);
        
        const mResponse = await fetch(url, { headers: elexity.admin.monitor.graphs.authToken });
        const mJson = await mResponse.json();
        
        if (!mResponse.ok) {
            if (mResponse.status === 401) {
                authentication.logout()
                window.location.replace(authentication.logoutLink)
            }
        }

        callback(mJson);
    },
    
    logEvent : function(_event) {
//        console.log(new Date().toISOString() + event);        
    },
    
    initAuth(authToken) {
        elexity.admin.monitor.graphs.authToken = authToken;
    },
    
    init : function(entityKey, definitionUrl, liveUrl, graphConfiguration, liveDivName, subtitle) {
        elexity.admin.monitor.graphs.logEvent("init : enter");
        elexity.admin.monitor.graphs.entityKey = entityKey;
        elexity.admin.monitor.graphs.definitionUrl = definitionUrl.replaceAll("{entityKey}", entityKey);
        elexity.admin.monitor.graphs.liveUrl = liveUrl.replaceAll("{entityKey}", entityKey);       
        elexity.admin.monitor.graphs.graphConfiguration = graphConfiguration; 
        elexity.admin.monitor.graphs.liveDivName = liveDivName; 
        elexity.admin.monitor.graphs.liveHistorySubtitle = subtitle;
    
        elexity.admin.monitor.graphs.performanceLiveGraph = null;
        elexity.admin.monitor.graphs.liveLoading = false;
        elexity.admin.monitor.graphs.focusFromTimestamp = null;
        elexity.admin.monitor.graphs.focusToTimestamp = null;
        elexity.admin.monitor.graphs.lastLiveTimestamp = null;
                        
        elexity.admin.monitor.graphs.logAndload("define : ", elexity.admin.monitor.graphs.definitionUrl, elexity.admin.monitor.graphs.initializeGraphs);        
                
        if (elexity.admin.monitor.graphs.intervalHook != null) {
            clearInterval(elexity.admin.monitor.graphs.intervalHook);
            elexity.admin.monitor.graphs.intervalHook = null;
        }        
        elexity.admin.monitor.graphs.logEvent("init : exit");
    },
    
    halt : function() {
        elexity.admin.monitor.graphs.logEvent("halt : enter");
        elexity.admin.monitor.graphs.performanceLiveGraph = null;
        elexity.admin.monitor.graphs.liveLoading = false;
        elexity.admin.monitor.graphs.focusFromTimestamp = null;
        elexity.admin.monitor.graphs.focusToTimestamp = null;
        elexity.admin.monitor.graphs.lastLiveTimestamp = null;
                        
        if (elexity.admin.monitor.graphs.intervalHook != null) {
            clearInterval(elexity.admin.monitor.graphs.intervalHook);
            elexity.admin.monitor.graphs.intervalHook = null;
        }        

        elexity.admin.monitor.graphs.logEvent("halt : exit");
    },
    
    pulseLiveGraph : function() {
        elexity.admin.monitor.graphs.logEvent("pulseLiveGraph : enter");
        if (elexity.admin.monitor.graphs.performanceLiveGraph != null && !elexity.admin.monitor.graphs.liveLoading) {
            elexity.admin.monitor.graphs.liveLoading = true;
            var mUrl = elexity.admin.monitor.graphs.liveUrl;
            if (elexity.admin.monitor.graphs.lastLiveTimestamp != null) {
                mUrl += "?liveFrom=" + elexity.admin.monitor.graphs.lastLiveTimestamp;
            }

            elexity.admin.monitor.graphs.logAndload("pulse : live : ", mUrl, elexity.admin.monitor.graphs.renderLiveGraphData);
        }
        elexity.admin.monitor.graphs.logEvent("pulseLiveGraph : exit");
    },
    	        
    createBaseDefinition : function() {
        var mBaseGraphDefinition = {
            chart : { 
                zooming : { 
                    mouseWheel : {
                        enabled : false
                    }
                }  },           
            credits: { enabled: false },                
            title: { text : "" }, 
            // subtitle : { text : "" },              
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%b-%e %H:%M}'
                }, 
                ordinal : false,
                tickInterval: 1 * 60 * 60 * 1000 * 4,
                // tickPositions: [0, 1, 2, 4, 8]
            },
            yAxis: [{
                lineWidth: 1,
                title: {
                    text: elexity.admin.monitor.graphs.graphDefinition.unit
                },
                opposite: false
            }],
            tooltip: {
                enabled: false
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                        radius: 3
                    },
                    lineWidth: 3,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            time: {
                timezone: elexity.admin.monitor.graphs.graphDefinition.timeZoneCode
            },
            legend: {
                enabled: true,
            },
            exporting: {
                enabled: false,
            },
            series: elexity.admin.monitor.graphs.graphConfiguration.seriesDefinitions
        };
        
        if (elexity.admin.monitor.graphs.graphConfiguration.yAxes != null) {
            elexity.admin.monitor.graphs.graphConfiguration.yAxes.forEach(yAxis => {
                mBaseGraphDefinition.yAxis.push(yAxis)});
        }
        
        return mBaseGraphDefinition;
    },     
        
    initializeGraphs : function(graphDefinition) {
        elexity.admin.monitor.graphs.logEvent("initializeGraphs : enter");
        
        elexity.admin.monitor.graphs.graphDefinition = graphDefinition;
        
        elexity.admin.monitor.graphs.initializeLiveGraph();
        
        setTimeout(elexity.admin.monitor.graphs.postInitialize, 10);

        elexity.admin.monitor.graphs.logEvent("initializeGraphs : exit");
    },
    
    postInitialize : function() {
        elexity.admin.monitor.graphs.logEvent("postInitialize : enter");
        elexity.admin.monitor.graphs.pulseLiveGraph();
        elexity.admin.monitor.graphs.intervalHook = setInterval(elexity.admin.monitor.graphs.pulseLiveGraph, 15000);          
        elexity.admin.monitor.graphs.logEvent("postInitialize : exit");
    },     

    initializeLiveGraph : function() {
        elexity.admin.monitor.graphs.logEvent("initializeLiveGraph : enter");
        if (elexity.admin.monitor.graphs.performanceLiveGraph == null) {
            var mGraphDefinition = elexity.admin.monitor.graphs.createBaseDefinition(elexity.admin.monitor.graphs.graphDefinition);
            
            var mExtremesEvent = function(event) {
                var mGraphUpdateDefinition = {
                    subtitle: { text: elexity.admin.monitor.graphs.liveHistorySubtitle }                
                };

                elexity.admin.monitor.graphs.performanceLiveGraph.update(mGraphUpdateDefinition, true);
            };
    
            mGraphDefinition.xAxis.events = {};            
            mGraphDefinition.xAxis.events.setExtremes = mExtremesEvent;

            mGraphDefinition.xAxis.minRange = 1 * 60 * 1000;

            mGraphDefinition.xAxis.labels.format = '{value:%b-%e %H:%M}';

            this.performanceLiveGraph = Highcharts.chart(elexity.admin.monitor.graphs.liveDivName, mGraphDefinition); 
        }
        elexity.admin.monitor.graphs.logEvent("initializeLiveGraph : exit");
    },

    calculatePlotBands : function(graphModel) {
        var mPlotBands = [];
        if (graphModel.ranges != null && elexity.admin.monitor.graphs.graphConfiguration.plotBandColors != null && elexity.admin.monitor.graphs.graphConfiguration.plotBandColors != undefined) {
            graphModel.ranges.forEach(range => {
                var mColor = elexity.admin.monitor.graphs.graphConfiguration.plotBandColors[range.rangeCode];
    
                if (mColor != null && mColor != undefined && mColor != "") {
                    var mPlotBand = {
                        from: range.startDateTime,
                        to: range.endDateTime,
                        color: mColor
                    };
    
                    mPlotBands.push(mPlotBand);             
                }            
            });
        }
    
        return mPlotBands;
    },
	
    calculatePlotLines : function(graphModel) {
        var mPlotLines = [];
        if (graphModel.events != null) {
            graphModel.events.forEach(event => {
                  let mPlotLine = {
                    color: '#000000',
                    label: {
                        text: event.eventName,
                        y: 22,
                    },
                    value: event.dateTime,
                    width: 2,
                }
                 mPlotLines.push(mPlotLine)
            });
        }
    
        return mPlotLines;
    },
    
    getNameToIndexMap : function(graphModel) {
        var mNameToIndexMap = {};
        graphModel?.seriesNames?.forEach((seriesName, index) => {
            var mSeriesName = seriesName;
            if (mSeriesName == 'TPPL') {
                mSeriesName = "Limit";
            }
            
            mNameToIndexMap[mSeriesName] = index;
        });

        return mNameToIndexMap;
    },

    renderLiveGraphData : function(graphModel) {
        var date = new Date();
        var timeStamp = date - 1000 * 60 * 60 * 24 * 2;
        
        elexity.admin.monitor.graphs.logEvent("renderLiveGraphData : enter");
        if (elexity.admin.monitor.graphs.performanceLiveGraph != null) {    
            elexity.admin.monitor.graphs.lastLiveTimestamp = graphModel.nextQueryTimestamp;

            var mNameToIndexMap = elexity.admin.monitor.graphs.getNameToIndexMap(graphModel);
            
            elexity.admin.monitor.graphs.graphConfiguration.seriesDefinitions.forEach((mSeriesDef, seriesIndex) => {
                var mDataIndex = mNameToIndexMap[mSeriesDef.name];
                if (mDataIndex != null && mDataIndex != undefined) {
                    var mDataPoints = [];

                    graphModel.entries.forEach((entry) => {
                        if (entry.values[mDataIndex] != null && elexity.admin.monitor.graphs.performanceLiveGraph.series != null  && elexity.admin.monitor.graphs.performanceLiveGraph.series[seriesIndex] != null) {
                            var mValue = entry.values[mDataIndex];
                            mDataPoints.push([entry.timestamp, mValue]);
                        }
                    });
                     
                    if (elexity.admin.monitor.graphs.performanceLiveGraph.series != null  && elexity.admin.monitor.graphs.performanceLiveGraph.series[seriesIndex] != null) {
                        elexity.admin.monitor.graphs.performanceLiveGraph.series[seriesIndex].setData(mDataPoints, false);                        
                    }                   
                }
            });
            
            var mGraphUpdateDefinition = {
                xAxis : {
                    min : timeStamp,
                    max : graphModel.displayEndTimestamp                
                }
            };
            
            elexity.admin.monitor.graphs.performanceLiveGraph.update(mGraphUpdateDefinition, true);

            elexity.admin.monitor.graphs.liveLoading = false;
        }
        elexity.admin.monitor.graphs.logEvent("renderLiveGraphData : exit");        
    },
    
    roundToNumber : function(num, precision) {
        if (num == null || num == undefined) {
            return null;
        }
        else {
            var mMult = 10 ** precision;
            var mRet = Math.round(num * mMult) / mMult;
            return mRet;
        }
    },
    
    buildDateString : function(ts, tz) {
        var mFormat = new Intl.DateTimeFormat('en-GB', {
            dateStyle: "medium",            
            timeStyle: "long",
            hourCycle: "h24",
            timeZone: tz
        });
        
        var mDate = new Date(ts);

        return mFormat.format(mDate);
    },

    buildDateRangeString : function(tsFrom, tsTo, tz) {
        var mFormat = new Intl.DateTimeFormat('en-GB', {
            dateStyle: "medium",            
            // timeStyle: "long",
            // hourCycle: "h24",
            timeZone: tz
        });
        
        var mDateFrom = new Date(tsFrom);
        var mDateTo = new Date(tsTo);

        var mRet = '';

        try {
            mRet = mFormat.format(mDateFrom) + ' to ' + mFormat.format(mDateTo);            
        }
        catch (e) {
            
        }
        
        return mRet;
    }
}

export { elexity }