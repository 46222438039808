import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import authentication from '../utilities/Authentication';
import configuration from '../config';

import WithRouter from '../utilities/WithRouter';
import { setCurrentCampus, setCurrentDeviceData } from '../actions';
import EVContent from './subsections/EVContent';
import BatteryContent from './subsections/BatteryContent';
import EditEvGroup from '../components/modals/EditEVGroup';
import EVOverride from '../components/modals/EVOverride';
import ConfirmModal from '../components/modals/ConfirmModal';
import Loading from '../components/Loading';
import Message from '../components/Message';
import AuthenticatedComponent from '../utilities/AuthenticatedComponent';

import icon_devices from '../assets/icon_devices.svg';

const Devices = ({ 
    currentCampusId, 
    currentDeviceData, 
    updateDeviceData, 
    deviceCallback, 
    showMobile 
}) => {
    const [hasEVs, setHasEvs] = useState(true);
    const [deviceMenuActive, setDeviceMenuActive] = useState('EV');
    const [selectedEVModal, setSelectedEVModal] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGXId, setSelectedGXId] = useState(null);
    const [allEVs, setAllEVs] = useState([]);
    const [groupIdDelete, setGroupIdDelete] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [modalMessage, setModalMessage] = useState("");
    const [confirmAction, setConfirmAction] = useState("");

    const authenticatedComponent = new AuthenticatedComponent
    const powerDecisionsOrder = ["Microgrid", "Critical", "Conserve",  "Standard", "Performance"]

    let newPowerDecisions = {}
    let initialPowerDecisions
  
    currentDeviceData?.gridInterconnectEvChargers?.map((item) => {
      item?.evChargerGroups.map((group) => {
        initialPowerDecisions = group.powerDecisionLimits
      })
    })
  
    powerDecisionsOrder.forEach(key => {
      if (initialPowerDecisions?.hasOwnProperty(key)) {
        newPowerDecisions[key] = initialPowerDecisions[key]
      }
    })

    //Call device API when campus change
    useEffect(() => {
        authenticatedComponent.getDeviceData(currentCampusId, deviceCallback)
    }, [currentCampusId])

    //When device data updates, reload
    useEffect(() => {
        //Device data update every 5 seconds
        if (currentDeviceData) {
            if (currentDeviceData.gridInterconnectEvChargers) {
                //check if group has any EVs
                currentDeviceData.gridInterconnectEvChargers?.map((group) => 
                    setHasEvs(group.evChargerGroups.length > 0)
                )
            }
            
            let interval = setInterval(() => {
                updateDeviceData()
                }, 5000)
        
                return () => clearInterval(interval)
        }
    }, [currentDeviceData])

    const handleMenuClick = (type) => {
        setDeviceMenuActive('')
        setDeviceMenuActive(type)
    }

    const clearModal = () => {
        setSelectedEVModal(null)
    }

    const handleCancelOverride = () => {
        clearModal()
        setProcessing(true)

        // Send data to the backend via POST
        fetch(configuration['backend_host'] + '/ahiapi/'
        + selectedGXId + '/gridInterconnect/evChargerGroup/override/' + groupIdDelete,
        {
            headers: authenticatedComponent.generateAuthenticatedHeader(true),
            method: 'DELETE', 
            body: null
        })

        .then(async response => {
            const res = await response.json()
            if (!response.ok) {
                clearModal();

                setErrorMessage(res.message);

                setTimeout(() => {
                setErrorMessage(null)
                }, 9000)

                throw new Error(res.message)
            } else {
                if (res) {
                    authenticatedComponent.getDeviceData(currentCampusId, deviceCallback)
                }

                return res
            }
        })

        .then(() => {
            setTimeout(() => {
                authenticatedComponent.getDeviceData(currentCampusId, deviceCallback)
                setModalMessage("")
                setProcessing(false)
            }, 5000)
        })

        .catch(error => {
            clearModal();
            setProcessing(false)

            setTimeout(() => {
                if (errorMessage === null) {
                setErrorMessage('Something went wrong. Try again or contact customer support.');

                setTimeout(() => {
                    setErrorMessage(null)
                }, 9000)
                }
            }, 1000)

            console.error(error)
        })
    }

    const handleDelete = () => {
        clearModal()
        setProcessing(true)
        
        // Send delete request to the backend via DELETE
        fetch(configuration['backend_host'] + '/ahiapi/'
        + selectedGXId + '/gridInterconnect/evChargerGroup/' + groupIdDelete, 
        {
            headers: authenticatedComponent.generateAuthenticatedHeader(true),
            method: 'DELETE', 
            body: null
        })

        .then(async response => {
            const res = await response.json()
            if (!response.ok) {
                clearModal();
        
                setErrorMessage(res.message);
        
                setTimeout(() => {
                setErrorMessage(null)
                }, 9000)
        
                throw new Error(res.message)
            } else {
                return res
            }
          })
      
          .then(() => {
            setTimeout(() => {
                authenticatedComponent.getDeviceData(currentCampusId, deviceCallback)
                setGroupIdDelete(null)  
                setProcessing(false)
            }, 5000)
        })

        .catch(error => {
            clearModal();
            setProcessing(false)
      
            setTimeout(() => {
              if (errorMessage === null) {
                setErrorMessage('Something went wrong. Try again or contact customer support.');
        
                setTimeout(() => {
                  setErrorMessage(null)
                  setProcessing(false)
                }, 9000)
              }
            }, 1000)
      
            console.error(error)
        })
    }

    const openEVModal = (selectModal, groupId, action, gxId) => {
        setSelectedGXId(gxId)

        setConfirmAction(action)
        setSelectedEVModal(selectModal)
        
        if (selectModal === "edit" || selectModal === "override") {
            authenticatedComponent.getDeviceData(currentCampusId, deviceCallback)

            currentDeviceData.gridInterconnectEvChargers.map((groups) => {
                if (groups.gridInterconnectEntityKey === gxId) {
                    setAllEVs([])
    
                    groups.evChargerGroups.map((group) => {
                        group.powerDecisionLimits = newPowerDecisions
                        group.evChargers.map((charger) => {
                            setAllEVs(prevState => [...prevState, charger])
                        })

                        if (groupId !== "") {
                            if (group.evChargerGroupId === groupId) {
                                setSelectedGroup(group)
                            }
                        } else {
                            setSelectedGroup({
                                evChargerGroupName: "New Group",
                                evChargerGroupId: "",
                                evChargers: [],
                                powerDecisionLimits: {
                                    "Microgrid": 0,
                                    "Critical": 0,
                                    "Conserve": 0,
                                    "Standard": 0,
                                    "Performance": 0
                                }
                            })
                        }
                    })
                }
            })
        }

        if (selectModal === "confirm") {
            setSelectedGXId(gxId)
            setGroupIdDelete(groupId)

            if (action === "delete") {
                setModalMessage("Are you sure you want to delete this group?")
            } else {
                setModalMessage("Are you sure you want to cancel the override for this group?")
            }
        }
    }

    const renderModal = () => {
    switch(selectedEVModal) {
        case "edit":
        return <EditEvGroup 
                    modalImage={icon_devices}
                    clearModal={clearModal}
                    currentCampusId={currentCampusId}
                    currentDeviceData={currentDeviceData}
                    selectedGXId={selectedGXId}
                    selectedGroup={selectedGroup}
                    setProcessing={setProcessing}
                    setErrorMessage={setErrorMessage}
                    deviceCallback={deviceCallback}
                    showMobile={showMobile}
                    allEVs={allEVs}
                />
        case "override":
        return <EVOverride 
                    modalMessage={""}
                    modalImage={icon_devices}
                    clearModal={clearModal}
                    currentCampusId={currentCampusId}
                    selectedGXId={selectedGXId}
                    selectedGroup={selectedGroup}
                    setProcessing={setProcessing}
                    setErrorMessage={setErrorMessage}
                    deviceCallback={deviceCallback}
                    showMobile={showMobile}
                />
        case "confirm":
        return <ConfirmModal 
                    modalMessage={modalMessage}
                    modalAction={confirmAction === "delete" ? 
                    () => handleDelete() : 
                    () => handleCancelOverride()}
                    clearModal={clearModal}
               />
        case "loading":
        return <Loading message="Processing request" />
        default:
        return false
    }
    }

    const modal = renderModal();

    return (
        <DevicesSection className={showMobile ? 'mobile' : 'desktop'}>
            {processing && (<Loading message='Processing request' />)}
            {errorMessage && (<Message displayMessage={errorMessage} type='error' />)}

            <div className="header-container">
                <div className="title-menu">
                    <div className="header">
                        <img src={icon_devices} alt="network of devices icon" width="18" height="18" />
                        <h1>Devices</h1>
                    </div>
                    
                    <nav className="devices-navigation">
                        {showMobile ? (<>
                            <select name="mobile-menu" id="mobile-menu" onChange={(e) => handleMenuClick(e.target.value)}>
                                <option value="EV">
                                    EV
                                </option>
                                <option value="battery">
                                    Battery
                                </option>
                            </select>
                        </>) : (<>
                            <NavLink to={`/devices/${currentCampusId}/ev`}>
                                <div 
                                    className={deviceMenuActive === 'EV' ? 'active' : ''}
                                    onClick={() => handleMenuClick('EV')}
                                >
                                    EV
                                </div>
                            </NavLink>

                            <NavLink to={`/devices/${currentCampusId}/battery`}>
                                <div 
                                className={deviceMenuActive === 'Battery' ? 'active' : ''}
                                onClick={() => handleMenuClick('Battery')}
                                >
                                    Battery
                                </div>
                            </NavLink>
                        </>)}
                    </nav>
                </div>
            </div>
            
            <section className="devices-section">
                {modal}

                {deviceMenuActive === 'EV' ? 
                (<>
                    {hasEVs ? (
                        <EVContent
                            openEVModal={openEVModal}
                            showMobile={showMobile}
                            currentDeviceData={currentDeviceData}
                            newPowerDecisions={newPowerDecisions}
                        />
                    ) : (<><div>No EVs Connected</div></>)}
                    
                </>)
                :
                (
                    <BatteryContent
                        // openEVModal={openEVModal}
                        currentDeviceData={currentDeviceData}
                        showMobile={showMobile} 
                    />
                )}
            </section>
        </DevicesSection>
    )
}

const DevicesSection = styled.section`
    color: #02222B;
    height: 100vh;
    overflow: auto;

    h3 {
        font-size: 16px;
    }

    .header-container,
    .title-menu {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .title-menu {
        max-width: 350px;
    }

    .header-container {
        margin-bottom: 12px;

        nav.devices-navigation {
            display: flex;
            justify-content: space-between;
            line-height: 26px;
            margin-left: 24px;
            padding-bottom: 14px;

            div {
                cursor: pointer;
                font-size: 16px;
                font-weight: 600;
                list-style: none;
                margin: 0px 4px;
                opacity: 0.5;
                padding-bottom: 4px;
                text-align: center;
                width: 100px;

                &.active {
                    border-bottom: 3px #00E3AB solid;
                    opacity: 1;
                }
            }
        }
    }

    .devices-section {
        margin-right: 24px;
    }
`

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentDeviceData: state.currentDeviceData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
        dispatch(setCurrentCampus(campusId))
    },
    setCurrentDeviceData: (deviceData) => {
        dispatch(setCurrentDeviceData(deviceData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Devices))