import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { elexity } from '../utilities/AdvancedGraphHelper';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

import {
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  updateCurrentCampusData
} from '../actions'

import WithRouter from '../utilities/WithRouter';
import icon_chevron from '../assets/icon_chevron.svg';

const Breadcrumbs = ({ 
  currentCampusData,
  currentCampusId,
  currentBuildingId,
  currentZoneId,
  currentGXId,
  hasSingleGX,
  activePage,
  onZoneChange,
  showMobile,
  onBuildingChange,
  onGXChange,
}) => {
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false)
  const zoneParam = window.location.pathname.split('/')[4]
  const pageUrl = window.location.pathname.split('/')[1]
  const showBuildingNav = currentCampusData && currentZoneId
  const showGXNav = currentCampusData &&  currentGXId && !hasSingleGX
  const showComfortBreadcrumbs = pageUrl === 'comfort' && showBuildingNav
  const showEnergyBreadcrumbs = pageUrl === 'energy' && showGXNav

  useEffect(() => {
    setShowBreadcrumbs(showComfortBreadcrumbs || showEnergyBreadcrumbs)

    if (currentCampusData && currentCampusData.buildings && showMobile) {
      currentCampusData.buildings.filter((building) => {
        if (building.buildingDescription.buildingEntityKey === currentBuildingId) {          
          if (zoneParam === undefined || zoneParam === null) {
            onZoneChange(null)
          }          
        }
      })
    }
  }, [currentCampusData, onBuildingChange])

  const handleSubNavCampus = () => {
    onBuildingChange(null)
    onZoneChange(null)
    onGXChange(null)
    onGXChange(null)

    elexity.admin.monitor.graphs.halt()
  }

  return (<>
    {showBreadcrumbs && (
      <StyledBreadcrumbsDiv>
        {(showBuildingNav || showGXNav) && 
          !hasSingleGX &&
          currentCampusData?.campusDescription &&
        (
            <NavLink
              className="campus-name"
              to={`/${activePage}/${currentCampusId}`}
              onClick={() => handleSubNavCampus()}
            >
              <img src={icon_chevron} alt="indicator arrow to go back to campus" width={15} />
              {currentCampusData.campusDescription.longCampusDisplayName}
            </NavLink>
        )}
      </StyledBreadcrumbsDiv>
    )}
  </>)
}

const StyledBreadcrumbsDiv = styled.div`
    margin-left: 24px;

  .campus-name {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    img {
      margin: 0 4px;
      padding: 0;
      transform: rotate(90deg);
    }
  }
`

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    campuses: state.campuses
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
      dispatch(setCurrentCampus(campusId))
    },
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentZone: (zoneId) => {
      dispatch(setCurrentZone(zoneId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    },
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Breadcrumbs))