import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import styled from '@emotion/styled';

import WithRouter from '../utilities/WithRouter';
import {
    setCurrentCampus,
    setCurrentBuilding,
    setCurrentZone,
    setCurrentZoneData,
    setCurrentGX,
    updateCurrentCampusData
} from '../actions'

import CurrentWeather from '../components/CurrentWeather';
import Breadcrumbs from '../components/Breadcrumbs';
import AuthenticatedComponent from '../utilities/AuthenticatedComponent';

import icon_comfort from '../assets/icon_comfort.svg';
import icon_building from '../assets/icon_building.svg';
import ZoneCard from '../components/ZoneCard';
import ZoneSettings from './subsections/ZoneSettings';

const Comfort = ({ 
    router,
    currentCampusId,
    currentCampusData,
    updateCampusCallback,
    currentBuildingId,
    currentZoneId,
    currentZoneData,
    zoneSummaryCallback,
    onBuildingChange,
    onGXChange,
    onZoneChange,
    setCurrentScheduleId,
    showMobile,
    openScheduleModal,
    allScheduleCallback,
    currentScheduleData,
    activePage
 }) => {
    const [buildingZoneData, setBuildingZoneData] = useState(null)
    const [zoneHealth, setZoneHealth] = useState(null)
    const [sortedCampusBuildings, setSortedCampusBuildings] = useState([])

    const authenticatedComponent = new AuthenticatedComponent

    useEffect(() => {
        if (currentCampusData && currentCampusData.buildings) {
            getBuildingsArray()
        }
    },[])

    // When device data updates, reload
    useEffect(() => {
        //Device data update every 5 seconds
        if (currentCampusData) {
            if (currentCampusData.buildings) {
                getBuildingsArray()
            }

            if (currentZoneId === null) {
                let interval = setInterval(() => {
                    authenticatedComponent.updateCampusData(currentCampusId, updateCampusCallback)
                }, 5000)
            
                return () => clearInterval(interval)
            }

        }
    }, [currentCampusData])

    const getBuildingsArray = () => {
        let tempBuidlings = []

        tempBuidlings = currentCampusData.buildings.sort((a,b) => {
            if (a.buildingDescription.buildingEntityKey - b.buildingDescription.buildingEntityKey) {
              return 1;
            }
            if (a.buildingDescription.buildingEntityKey < b.buildingDescription.buildingEntityKey) {
              return -1;
            }
            return 0
        })
    
        for (let i = 0; i < tempBuidlings.length; i++) {
            tempBuidlings[i].thermalZones.sort((a,b) => {
                if (a.longThermalZoneDisplayName - b.longThermalZoneDisplayName) {
                    return 1;
                  }
                  if (a.longThermalZoneDisplayName < b.longThermalZoneDisplayName) {
                    return -1;
                  }
                  return 0
            })
        }

        setSortedCampusBuildings(tempBuidlings)
    }

    const navigateWithValidation = (newPath) => {
        if (window.location.pathname !== newPath) {
          router.navigate(newPath)
        }
    }

    const selectZone = (selectedZoneId, buildingId) => {
        if (selectedZoneId) {
            setCurrentZone(selectedZoneId)
            setCurrentBuilding(buildingId)
            setCurrentGX(null)
            setCurrentZoneData(null)
            
            navigateWithValidation('/comfort/' + currentCampusData.campusDescription.campusEntityKey
              + '/' + buildingId + '/' + selectedZoneId)
    
          authenticatedComponent.getZoneSummary(selectedZoneId, zoneSummaryCallback)
        }
    }

    const buildingZoneCallback = (data) => {
        setBuildingZoneData(data)
    }

    return (
        <ComfortSection className={showMobile ? "comfort-section mobile" : "comfort-section desktop"}>
            <div className="header-container">
                <div className="header">
                    <img src={icon_comfort} alt="network of devices icon" width="18" height="18" />
                    <h1>Comfort</h1>

                    {currentZoneId !== null && 
                    
                    <Breadcrumbs
                        currentCampusData={currentCampusData}
                        currentCampusId={currentCampusId}
                        currentBuildingId={currentBuildingId}
                        currentZoneId={currentZoneId}
                        currentGXId={null}
                        hasSingleGX={null}
                        activePage={activePage}
                        showMobile={showMobile}
                        onBuildingChange={onBuildingChange}
                        onGXChange={onGXChange}
                        onZoneChange={onZoneChange}
                    />}
                </div>

                <CurrentWeather currentCampusData={currentCampusData}></CurrentWeather>
            </div>
            
            <div className="comfort-content">
                {currentCampusData && currentZoneId === null ? 
                    (<>
                    {sortedCampusBuildings.map((building, index) => (<React.Fragment key={`building-${index}`}>
                        <div className="building-container">
                            <div className="building-header">
                                <img src={icon_building} alt="building icon" width="20" height="20" />
                                <h3 className="building-name">{building.buildingDescription.longBuildingDisplayName}</h3>
                            </div>

                            <div className="thermal-zones">
                                <table>
                                    <tbody>
                                        <tr className="labels">
                                            <th className="head">Location</th>
                                            <th className="head">Status</th>
                                            <th className="head">Temp Range</th>
                                            <th className="head">Schedule</th>
                                            <th className="head">Activity</th>
                                            <th className="head">Keypad</th>
                                            <th className="head">Managed</th>
                                        </tr>

                                        {building.thermalZones.map((zoneData, index) => (
                                        <React.Fragment key={`${zoneData.shortThermalZoneDisplayName}${index}`}>
                                            <ZoneCard
                                                zoneRealtimeData={zoneData}
                                                selectZone={() => selectZone(zoneData.thermalZoneEntityKey, building.buildingDescription.buildingEntityKey)}
                                                setZoneHealth={setZoneHealth}
                                            />
                                        </React.Fragment>))}
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </React.Fragment>))
                }
                </>)
            : (<>
                <ZoneSettings
                    currentCampusId={currentCampusId}
                    currentCampusData={currentCampusData}
                    currentBuildingId={currentBuildingId}
                    buildingZoneData={buildingZoneData}
                    currentZoneId={currentZoneId}
                    currentZoneData={currentZoneData}
                    buildingZoneCallback={buildingZoneCallback}
                    zoneSummaryCallback={zoneSummaryCallback}
                    setCurrentScheduleId={setCurrentScheduleId}
                    allScheduleCallback={allScheduleCallback}
                    currentScheduleData={currentScheduleData}
                    showMobile={showMobile}
                    openScheduleModal={openScheduleModal}
                    zoneHealth={zoneHealth}
                    setZoneHealth={setZoneHealth}
                />
            </>)    
            }
            </div>
        </ComfortSection>
    )
}

const ComfortSection = styled.section`
    color: #02222B;
    overflow: auto;
    padding-right: 24px;

    .comfort-content {
        margin-top: 24px;
    }

    .building-container {
        background: white;
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(237, 241, 242, 0.5);
        display: flex;
        flex-flow: column;
        margin-bottom: 24px;
        padding: 24px 24px 12px;
        row-gap: 12px;

        .building-header {
            align-items: center;
            display: flex;
            padding: 0 0 12px 12px;

            h3 {
                font-size: 14px;
                font-weight: 600;
                margin: 0 0 0 12px;
            }
        }

        //ZONE BUTTON ONLY ON MAIN COMFORT PAGE
        .thermal-zones .location {
            cursor: pointer;
            text-decoration: underline;
            transition: ease-in-out all 250ms;

            &:hover {
            color: rgba(2, 34, 43, 0.5);
            transition: ease-in-out all 250ms;
            }
        }

        table {
            border-collapse: collapse;
            font-size: 14px;
            text-align: left;
            width: 100%;

            th:nth-of-type(1), 
            td:nth-of-type(1) {
                max-width: 220px;
                width: 20%;
            }

            th:nth-of-type(2), 
            td:nth-of-type(2),
            th:nth-of-type(3), 
            td:nth-of-type(3),
            th:nth-of-type(4), 
            td:nth-of-type(4) {
                max-width: 130px;
                width: 12%;
            }

            th:nth-of-type(5), 
            td:nth-of-type(5),
            th:nth-of-type(6), 
            td:nth-of-type(6) {
                max-width: 60px;
                width: 5%;
            }

            th:nth-of-type(7), 
            td:nth-of-type(7) {
                max-width: 130px;
                width: 14%;
            }

            th {
                color: #02222B;
                font-weight: 500;
                padding: .625em .25em;
            }

            td {
                padding: .25em;
            }

            tr {
                align-items: center;
                border-bottom: 2px solid #EDF1F2;
                display: flex;
                justify-content: space-between;
            }

            tr:last-child {
                border-bottom: none;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .header-container .header {
            padding-bottom: 0;
        }

        .thermal-zones tr.labels {
            display: none;
        }

        .building-container .thermal-zones .location {
            max-width: 175px;
            width: 35%;
        }

        .building-container table td.temp-range {
            width: 100px;
            max-width: unset !important;
        }
    }

    @media screen and (max-width: 1024px) {
        th:nth-of-type(2), 
        td:nth-of-type(2) {
            display: none;
        }
    }
`

const mapStateToProps = (state) => {
    return {
      campuses: state.campuses,
      currentCampusId: state.currentCampusId,
      currentBuildingId: state.currentBuildingId,
      currentZoneId: state.currentZoneId,
      currentGXId: state.currentGXId,
      currentCampusData: state.currentCampusData,
      setCurrentZoneData: state.currentZoneData,
      currentUserData: state.currentUserData
    }
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentCampus: (campusId) => {
            dispatch(setCurrentCampus(campusId))
        },
        setCurrentBuilding: (buildingId) => {
            dispatch(setCurrentBuilding(buildingId))
        },
        setCurrentZone: (zoneId) => {
            dispatch(setCurrentZone(zoneId))
        },
        setCurrentZoneData: (zoneId) => {
            dispatch(setCurrentZoneData(zoneId))
        },
        setCurrentGX: (gxId) => {
            dispatch(setCurrentGX(gxId))
        },
        updateCurrentCampusData: (campusData) => {
            dispatch(updateCurrentCampusData(campusData))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Comfort))