import {Component} from 'react'

import authentication from './Authentication'
import configuration from '../config'
import { post } from 'jquery'


class AuthenticatedComponent extends Component {
  generateAuthenticatedHeader(addPostHeader) {
    const headerObj = authentication.generateHeaderObject()
    const authenticatedHeaders = new Headers(headerObj)
    if (addPostHeader) {
      authenticatedHeaders.set('Content-Type', 'application/json')
    }
    return authenticatedHeaders
  }

  /// UPDATE CAMPUS DATA ///
  updateCampusData(currentCampusId, updateCampusCallback) {
    let comfortUrl
  
    if (currentCampusId) {
      comfortUrl = configuration['backend_host'] + '/ahiapi/' 
        + currentCampusId + '/campus/comfort'
    } else {
      comfortUrl = configuration['backend_host'] + '/ahiapi/campus/comfort'
    }

    fetch(
      comfortUrl, 
      {
        headers: this.generateAuthenticatedHeader()
      }
    )
  
    .then(response => {
      if (!response.ok) {
        throw new Error('Not Accessible')
      }
      return response.json()
    })

    .then(data => {
      updateCampusCallback(data)
    })

    .catch(error => {
      console.error(error)
    })
  }

  /// GET SINGE BUILDING THERMAL ZONES ///
  getBuildingZoneData(currentBuildingId, buildingZoneCallback) {
    if (currentBuildingId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + currentBuildingId
          + '/building',
        {
          headers: this.generateAuthenticatedHeader()
        }
      )

      .then(response => response.json())

      .then(data => {
        buildingZoneCallback(data)
      })

      .catch(error => console.error(error))
    }
  }

  /// SINGLE THERMAL ZONE ///
  getZoneSummary(currentZoneId, zoneSummaryCallback) {
    if (currentZoneId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + currentZoneId
          + '/thermalZone',
        {
          headers: this.generateAuthenticatedHeader()
        }
      )

      .then(response => response.json())

      .then(data => {
        zoneSummaryCallback(data)
      })

      .catch(error => console.error(error))
    }
  }

  /// COMFORT DATA ///
  getComfortData(currentCampusId) {
    if (currentCampusId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + currentCampusId
          + '/campus/comfort',
        {
          headers: this.generateAuthenticatedHeader()
        }
      )

      .then(response => response.json())

      .then(data => {
        if (typeof this['successGetComfortData'] === 'function') {
              this.successGetComfortData(data)
        }
      })

      .catch(error => console.error(error))
    }
  }

  /// ENERGY DATA ///

  /// UPDATE GX DATA /// 
  updateGXData(currentCampusId, updateGXCallback) {
    if (currentCampusId) {
      fetch(
        configuration['backend_host'] 
            + '/ahiapi/' + currentCampusId
            + '/campus/energy', 
        {
          headers: this.generateAuthenticatedHeader()
        }
      )
    
      .then(response => {
        if (!response.ok) {
          throw new Error('Not Accessible')
        }
        return response.json()
      })

      .then(data => {
        updateGXCallback(data)
      })

      .catch(error => {
        console.error(error)
      })
    }
  }

  /// DEVICES DATA ///
  getDeviceData(currentCampusId, deviceCallback) {
    if (currentCampusId) {
      fetch(
        configuration['backend_host'] 
            + '/ahiapi/' + currentCampusId
            + '/campus/evChargers', 
        {
          headers: this.generateAuthenticatedHeader()
        }
      )
    
      .then(response => {
        if (!response.ok) {
          throw new Error('Not Accessible')
        }
        return response.json()
      })

      .then(data => {
        deviceCallback(data)
      })

      .catch(error => {
        console.error(error)
      })
    }
  }

  /// ALL SCHEDULES DATA ///
  getAllScheduleData(currentCampusId, level, allScheduleCallback) {
    if (currentCampusId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + currentCampusId
          + '/schedules/' + level,
        {
          headers: this.generateAuthenticatedHeader()
        }
      )

      .then(response => response.json())

      .then(data => {
        allScheduleCallback(data)
      })

      .catch(error => console.error(error))
    }
  }

  /// SELECTED SCHEDULE DATA ///
  getSelectedScheduleData(currentCampusId, scheduleId, selectedCallback) {
    if (currentCampusId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + currentCampusId
          + '/schedule/' + scheduleId,
        {
          headers: this.generateAuthenticatedHeader()
        }
      )

      .then(response => response.json())

      .then(data => {
        selectedCallback(data)
      })

      .catch(error => console.error(error))
    }
  }

  /// SCHEDULE ASSIGNMENTS ///
  getScheduleAssignments(currentCampusId, assignmentCallback) {
    if (currentCampusId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + currentCampusId
          + '/schedules/assignments',
        {
          headers: this.generateAuthenticatedHeader()
        }
      )

      .then(response => response.json())

      .then(data => {
        Object.entries(data).forEach(([key, buildings]) => {
          assignmentCallback(buildings)
        })
      })

      .catch(error => console.error(error))
    }
  }

  /// EVENTS LIST ///
  getEventsList(currentCampusId, eventsCallback) {
    if (currentCampusId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + currentCampusId
          + '/events',
        {
          headers: this.generateAuthenticatedHeader()
        }
      )

      .then(response => response.json())

      .then(data => {
        eventsCallback(data)
      })

      .catch(error => console.error(error))
    }
  }

  /// EVENTS ASSIGNMENT LIST ///
  getEventAssignmentList(currentCampusId, eventsAssignmentCallback) {
    if (currentCampusId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + currentCampusId
          + '/events/possibleAssignments',
        {
          headers: this.generateAuthenticatedHeader()
        }
      )

      .then(response => response.json())

      .then(data => {
        eventsAssignmentCallback(data)
      })

      .catch(error => console.error(error))
    }
  }

  /// USER PROFILE ///
  getUserProfile(successCallback) {
    fetch(configuration['backend_host'] + '/prefs/' + authentication.getUserId(),
    {
      headers: this.generateAuthenticatedHeader()
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Request failed')
        }
        return response.json()
      })

      .then(data => {
        successCallback(data)
      })

      .catch(data => {
        successCallback({
          id: authentication.getUserId(),
          'prefs': {}
        })
      })
  }

  /// KIOSK DATA ///
  fetchKioskData(gxParam, kioskCallback) {
    fetch(
      configuration['backend_host'] 
        + '/ahiapi/lobbyKiosk/' + gxParam
    )
    
    .then(response => {
      if (!response.ok) {
          throw new Error('Not Accessible')
      }
      return response.json()
    })

    .then(data => {
      kioskCallback(data)
    })

    .catch(error => {
      console.error(error)
    })
  }

  // initiateUserProfile(updatedValues, successCallback) {
  //   // get the latest version of the user profile
  //   this.getUserProfile(this.saveUserProfile.bind(this, updatedValues, successCallback))
  // }

  // saveUserProfile(updatedValues, successCallback, postData) {
  //   Object.assign(postData['prefs'], updatedValues)
  //   fetch(configuration['backend_host'] + '/prefs/' + authentication.getUserId(),
  //   {
  //     headers: this.generateAuthenticatedHeader(true),
  //     method: 'POST',
  //     body: JSON.stringify(postData)
  //   })

  //   .then(data => {
  //     if (successCallback) {
  //       successCallback(data)
  //     }
  //   })
  // }


  // /// ZONE SETTINGS ///
  // getOverrideData() {
  //   const { currentCampusData } = this.props;

  //   if (currentCampusData) {
  //   fetch(
  //     configuration['backend_host'] + '/schedule/'
  //       + currentCampusData['entityKey']['organizationCode']
  //       + '/' + currentCampusData['entityKey']['campusCode']
  //       + '/scheduledOverrides' +  '.json',
  //     {
  //       headers: this.generateAuthenticatedHeader()
  //     }
  //   )
  //     .then(response => response.json())
  //     .then(data => {
  //       return data;
  //     })
  //   }
  // }

  // saveDemandExSettings(zoneData, postData) {
  //   // Send data to the backend via POST
  //   fetch(configuration['backend_host'] + '/command/'
  //   + zoneData['entityKey']['organizationCode']
  //   + '/' + zoneData['entityKey']['campusCode']
  //   + '/' + zoneData['entityKey']['buildingIndex']
  //   + '/' + zoneData['entityKey']['thermalZoneIndex']
  //   + '/profile', 
  //   {
  //     headers: this.generateAuthenticatedHeader(true),
  //     method: 'POST', 
  //     body: JSON.stringify(postData)
  //   })

  //   .then(response => {
  //     response.json()
  //     // if (response.ok) {
  //     //   console.log(response)
  //     // }
  //   })
  //   .then(data => data)
  // }

  // saveOverrideZoneSettings() {
    
  // }
}

export default AuthenticatedComponent