import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import exportingModule from 'highcharts/modules/exporting';
import styled from '@emotion/styled';

import { elexity } from '../../utilities/AdvancedGraphHelper';
import AuthenticatedComponent from '../../utilities/AuthenticatedComponent';
import configuration from '../../config';
import { setCurrentGX } from '../../actions';
import { connect } from 'react-redux';
import WithRouter from '../../utilities/WithRouter';
import Breadcrumbs from '../../components/Breadcrumbs';

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

const EnergyUtilityMeterDetail = ({ 
    currentCampusData,
    currentCampusId,
    currentBuildingId,
    currentZoneId,
    currentGXId,
    hasSingleGX,
    currentGXData,
    onBuildingChange,
    onGXChange,
    onZoneChange,
    activePage,
    showMobile
}) => {
    exportingModule(Highcharts)

    const [gxName, setGXName] = useState(null)
    const [chartTabActive, setChartTabActive] = useState('live')
    const [graphSubtitle, setGraphSubtitle] = useState('')
    const [billData, setBillData] = useState([])
    const [hasBillData, setHasBillData] = useState(false)
    
    const authenticatedComponent = new AuthenticatedComponent

    function handleRefresh() {
        elexity.admin.monitor.graphs.triggerHistoryGraphReload()
    }

    useEffect(() => {
        getBills()
    }, [currentGXId])

    useEffect(() => {
        if (currentGXData?.gridInterconnects) {
            currentGXData.gridInterconnects.filter((gx) => {
                if (gx.gridInterconnectDescription.gridInterconnectEntityKey === currentGXId) {
                    setGXName(gx.gridInterconnectDescription.longGridInterconnectDisplayName)
                }

                if (gx.gridInterconnectDescription.gridInterconnectEntityKey === currentGXId) {
                    if (historyChartRef.current && liveChartRef.current && graphSubtitle === '') {
                        setGraphSubtitle(gx.gridInterconnectDescription.longGridInterconnectDisplayName)

                        elexity.admin.monitor.graphs.initAuth(authenticatedComponent.generateAuthenticatedHeader())
                        elexity.admin.monitor.graphs.init(
                            currentGXId,
                            configuration['backend_host']
                                + '/admin/monitor/{entityKey}/gridInterconnect/graphData/definition',
                            configuration['backend_host']
                                + '/admin/monitor/{entityKey}/gridInterconnect/graphData/history',
                            configuration['backend_host']
                                + '/admin/monitor/{entityKey}/gridInterconnect/graphData/live',
                            graphConfiguration,
                            "historyPerformanceGraph",
                            "livePerformanceGraph",
                            gx.gridInterconnectDescription.longGridInterconnectDisplayName
                        )
                    }
                }
            })    
        }
    }, [currentGXData])

    const historyChartRef = useRef(null)
    const liveChartRef = useRef(null)

    //Load initial graphs
    const graphConfiguration = ({
        seriesDefinitions : [
            {name: "Grid Power", color: "#FA974F", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            {name: "Solar Power", color: "#F5D500", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            {name: "HVAC Power", color: "#069BCE", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            {name: "EV Charger Power", color: "#0FFDC2", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            {name: "Battery Power", color: "#02BE7F", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}},
            {name: "Managed Load", color: "#ADD8E6", type: "column", stacking: 'normal', opacity: .75, dataGrouping: { approximation: 'high'}},
            // {name: "Limit", color: "#324E55", type: "spline", stacking: 'normal', opacity: .75, dataGrouping: { approximation: 'high'}},
            {name: "Unmanaged Load", color: "#CCCCCC", type: "column", stacking: 'normal', opacity: .75, dataGrouping: { approximation: 'high'}},
            {name: "Total Load", color: "#2FE0E6", type: "spline", opacity: 1, dataGrouping: { approximation: 'high'}}       
        ],

        defaultLiveZoom : 3
    })

    const handleKeyforId = (gxKey) => {
        return gxKey.replaceAll("~", "__")
    }

    function formatMonth(date) {
        const newDate = new Date(date)
        return newDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })
    }

    function formatStartEndDate(date) {
        return date.split("T")[0]
    }

    function generatePDFBill(pdfUri, fileName) {
        fetch(
            configuration['backend_host'] + '/ahiapi/' 
            + currentGXId + '/gridInterconnect/bills/pdf?uri=' + pdfUri + '&filename=' + `${fileName}.pdf`, 
            {
            headers: authenticatedComponent.generateAuthenticatedHeader()
            }
        )

        .then(response => {
            if (!response.ok) {
                throw new Error('Not Accessible')
            }
            return response
        })

        .then(data => {
            let alink = document.createElement("a");
            alink.href = data.url;
            // alink.download = fileName;
            alink.click();
        })

        .catch(error => {
            console.error(error)
        })
    }

    function getBills() {
        fetch(
            configuration['backend_host'] + '/ahiapi/' 
            + currentGXId + '/gridInterconnect/bills', 
            {
              headers: authenticatedComponent.generateAuthenticatedHeader()
            }
        )
        
        .then(response => {
            if (!response.ok) {
                throw new Error('Not Accessible')
            }
            return response.json()
        })

        .then(data => {
            setBillData(data)
            
            if (data.utilityApiDataAvailable === true) {
                setHasBillData(true)
            }
        })

        .catch(error => {
            setBillData([])
            console.error(error)
        })
    }

    return (<>
        <StyledMeterDetailDiv>
            {!hasSingleGX && (<>
                <div className="header-container">
                    <div className="header">
                        <h1 className="campus-name">{gxName ? gxName : "Utility Meter"}</h1>
                        
                        {currentGXId && (
                            <Breadcrumbs
                                currentCampusData={currentCampusData}
                                currentCampusId={currentCampusId}
                                currentBuildingId={currentBuildingId}
                                currentZoneId={currentZoneId}
                                currentGXId={currentGXId}
                                hasSingleGX={hasSingleGX}
                                activePage={activePage}
                                showMobile={showMobile}
                                onBuildingChange={onBuildingChange}
                                onGXChange={onGXChange}
                                onZoneChange={onZoneChange}
                            />
                        )}
                    </div>
                </div>
            </>)}
            
            <div className="gx-chart-container">
                <div className="chart-nav">
                    <div className="tabs">
                        <div
                            className={chartTabActive === 'live' ? "live active" : "live"}
                            onClick={() => setChartTabActive("live")}
                        >
                            Live
                        </div>
                        <div
                            className={chartTabActive === "history" ? "history active" : "history"}
                            onClick={() => setChartTabActive("history")}
                        >
                            History
                        </div>
                    </div>

                    {chartTabActive === "history" && (
                        <div 
                            className="refresh"
                            onClick={() => handleRefresh()}
                        >
                            Refresh Graph
                        </div>
                    )}
                </div>

                <div className="active-chart-container">
                    <div
                        id="historyPerformanceGraph"
                        className={chartTabActive === "history" ? "performanceGraph" : "hidden performanceGraph"}
                        ref={historyChartRef}
                    ></div>
                    <div
                        id="livePerformanceGraph"
                        className={chartTabActive === "live" ? "performanceGraph" : "hidden performanceGraph"}
                        ref={liveChartRef}
                    ></div>
                </div>
            </div>
            
            <div className="bill-analysis">
                <h4>Bill Analysis</h4>
                <hr />

                {hasBillData ? (
                    <div className="bill-table">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>kWh</th>
                                    <th>Total $</th>
                                    <th></th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {billData.length !== 0 && (
                                    billData.energyBills.map((item, index) => (
                                        <tr key={`bill-${index}`}>
                                            <td>{formatMonth(item.startDate)}</td>
                                            <td>{formatStartEndDate(item.startDate)}</td>
                                            <td>{formatStartEndDate(item.endDate)}</td>
                                            <td>{`${item.energykWh} kWh`}</td>
                                            <td>{`$${item.totalCost}`}</td>
                                            <td className="bill-download">
                                                <button 
                                                    id="pdf-bill-button" 
                                                    onClick={
                                                        () => generatePDFBill(item.pdfDownloadUrl, `${formatMonth(item.startDate)}-${currentGXId}_bill`)
                                                        }
                                                >
                                                    Get My Bill
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                ):(
                    <div className="no-data">To enable tracking of your utility bills please send a request to <a href='mailto:support@elexity.io'>support@elexity.io</a>.</div>
                )}
            </div>
        </StyledMeterDetailDiv>
    </>)
}

const StyledMeterDetailDiv = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    h1, h4 {
        color: #02222B;
        font-size: 16px;
        font-weight: 600;
        margin: 0
    }

    h1 {
        line-height: 28px;
    }

    hr {
        background: #EAECF0;
        border: none;
        height: 2px;
        margin: 20px 0 26px;
    }

    .gx-chart-container {
        background: white;
        border-radius: 8px;
        height: 100%;
        padding: 12px;

        .active-chart-container {
            margin: 0 24px;

            .performanceGraph {
                height: 600px;
            }
            .hidden {
                display: none;
                height: 0;
            }
        }
        
    }

    .bill-analysis {
        margin-top: 50px;

        .bill-table table {
            background: white;
            border: 3px #F2F4F7 solid;
            border-collapse: separate;
            border-radius: 12px;
            border-spacing: 0;
            width: 100%;

            th:first-of-type {
                width: 18%;
            }

            th {
                width: 16%;
            }

            tr {
                height: 35px;

                &:last-child td {
                    border-bottom: none;
                }
            }

            thead,
            td:first-of-type {
                background: #F2F4F7;
                color: #02222B;
                font-size: 12px;
                font-weight: 500;
                padding: 0 20px;
            }

            td {
                border-bottom: 2px solid #EAECF0;
                color: #02222B;
                font-size: 12px;
                font-weight: 500;

                &:nth-of-type(n + 2) {
                    text-align: center;
                }

                button {
                    background: #00E3AB;
                    border: none;
                    border-radius: 8px;
                    color: #02222B;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 3px 10px;
                    transition: ease-in-out all 250ms;

                    &:hover {
                        background: #006149;
                        color: white;
                        transition: ease-in-out all 250ms;
                    }
                }
            }
        }

        .no-data {
            border-radius: 8px;
            a {
                background: none;
                color: #02222B;
                padding: 0;
                text-decoration: underline;
                transition: ease-in-out all 250ms;
                &:hover {
                    background: none;
                    color: #02BE7F;
                    padding: 0;
                    transition: ease-in-out all 250ms;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .header-container {
            flex-flow: wrap;

            > div {
                margin-left: 0;
            }
        }
    }
`

const mapStateToProps = (state) => {
    return {
      campuses: state.campuses,
      currentCampusId: state.currentCampusId,
      currentGXId: state.currentGXId,
      currentCampusData: state.currentCampusData,
      currentGXData: state.currentGXData
    }
  }
  
  // used by parent class AuthenticatedComponent
  const mapDispatchToProps = (dispatch) => {
    return {
      setCurrentGX: (gxId) => {
        dispatch(setCurrentGX(gxId))
      },
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(EnergyUtilityMeterDetail))