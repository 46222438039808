import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

import { elexity } from '../utilities/AdvancedGraphHelper';
import elexity_logo from '../assets/elexity_logo_full.svg';
import icon_chevron from '../assets/icon_chevron.svg';
import icon_comfort from '../assets/icon_comfort.svg';
import icon_energy from '../assets/icon_energy.svg';
import icon_devices from '../assets/icon_devices.svg';
import icon_scheduler from '../assets/icon_scheduler.svg';
import icon_resources from '../assets/icon_resources.svg';
import icon_settings from '../assets/icon_settings.svg';
import icon_notifications from '../assets/icon_notifications.svg';
import icon_logout from '../assets/icon_logout.svg';
import icon_email from '../assets/icon_email.svg';
import icon_menu from '../assets/icon_menu.svg';
import icon_phone from '../assets/icon_phone.svg';
import withRouter from '../utilities/WithRouter';
import authentication from '../utilities/Authentication';
import configuration from '../config';
import AuthenticatedComponent from '../utilities/AuthenticatedComponent';

import {
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentGX,
  setCurrentGXData,
  setCurrentZone,
  updateCurrentCampusData,
} from '../actions'

const SideNav = ({
  campuses,
  currentCampusId,
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  updateCurrentCampusData,
  router,
  currentUserData,
  showMobile,
  activePage,
  setActivePage,
  currentWindowSize
}) => {
  const [showSidenav, setShowSidenav] = useState(false)
  const [sideNavClass, setSideNavClass] = useState(showMobile ? 'sidenav mobile mobile-hide' : 'sidenav hide')
  const authenticatedComponent = new AuthenticatedComponent
  const getHeader = authenticatedComponent.generateAuthenticatedHeader()
  const overlayRef = useRef(null)
  const firstName = currentUserData?.firstName ? currentUserData['first_name'] : ''

  useEffect(() => {
    resetMenu()
    window.addEventListener('click', hideSidenav)

    return () => {
        window.removeEventListener('click', hideSidenav)
    }
  }, [])

  useEffect(() => {
    resetMenu()
  }, [currentWindowSize, currentCampusId])

  const navigateWithValidation = (newPath) => {
    if (window.location.pathname !== newPath) {
      router.navigate(newPath)
    }
  }

  function hideSidenav(e) {
    if (e.target === overlayRef.current) {
      resetMenu()
    }
  }

  function resetCampus() {
    setCurrentBuilding(null)
    setCurrentZone(null)
    setCurrentGX(null)
  }

  function resetMenu() {
    if (window.innerWidth < 768 || window.screen.width < 768) {
      setShowSidenav(false)
      setSideNavClass('sidenav mobile mobile-hide')
    } else {
      setShowSidenav(false)
      setSideNavClass('sidenav hide')
    }

    navigateWithValidation(window.location.pathname)
  }

  const onOptionChange = (newCampusId) => {
    setCurrentCampus(newCampusId)

    if (currentCampusId !== newCampusId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + newCampusId
          + '/campus/comfort',
        {
          headers: getHeader
        }
      )

      .then(response => response.json())

      .then(data => {
        updateCurrentCampusData(data)
      })

      .catch(error => console.error(error))
    }

    navigateWithValidation(`/${activePage}/` + newCampusId)
    setCurrentGX(null)

    elexity.admin.monitor.graphs.halt()
    setShowSidenav(false)
  }

  const handleCampusChange = (e) => {
    onOptionChange(e.target.value, null)
    resetCampus()
  }

  const handlePageChange = (page) => {
    elexity.admin.monitor.graphs.halt()
    setActivePage(page)
    resetCampus()

    if (page === 'energy') {
      setCurrentBuilding(null)
      setCurrentZone(null)
    } else {
      setCurrentGX(null)
    }

    if (showSidenav) {
      setShowSidenav(false)
      setSideNavClass('sidenav hide')
      
      if (showMobile) { 
        resetMenu()
      }
    }
  }

  const handleMobileMenuClick = () => {
    setShowSidenav(!showSidenav)
    setSideNavClass('sidenav mobile')
  }

  const handleMenuClick = () => {
    if (!showSidenav) {
      setShowSidenav(!showSidenav)
      setSideNavClass('sidenav')
    } else {
      setShowSidenav(!showSidenav)
      setSideNavClass('sidenav hide')
    }
  }

  const renderProfileInfo = () => {
    if (currentUserData) {
      return (
        <>
          <div className='user-container'>          
            <div className='sidenav-profile-name'>
              Welcome{firstName !== '' ? `, ${firstName}` : '!'}
            </div>
          </div>
        </>
      )
    }
    return false
  }

  return (<>
    <StyledSideNavDiv>
      {showMobile && (
        <div className='mobile-nav'>
          <div className="mobile-menu-button" onClick={() => handleMobileMenuClick()}>
            <img src={icon_menu} alt='mobile hamburger menu icon' width='24' height='24' />
          </div>

          <div className="mobile-logo">
            <img src={elexity_logo} alt='elexity logo' width='142' height='32' />
          </div>
        </div>
      )}

      <div className={sideNavClass}>
        {!showMobile && (<>
          <div 
            className="nav-arrow"
            onClick={() => handleMenuClick()}
          >
            <img src={icon_chevron} alt="arrow to expand or close nav menu" width='20' height='20'/>
          </div>
        </>)}
        
        <div className='sidenav-main-container'>
          <div className='sidenav-top'>
            <div className='elexity-logo'>
              <img src={elexity_logo} alt='elexity logo' width='142' height='32' />
            </div>

            {renderProfileInfo()}

            <div className='campus-dropdown'>
              <select 
                className='campus-menu'
                onChange={handleCampusChange}
                value={(currentCampusId === null) ? '' : currentCampusId}
              >
                <option value="" disabled>Select a Campus</option>
                {campuses && campuses.map((campus) => (
                  <option 
                    key={`side-bar-${campus.campusEntityKey}`}
                    value={campus.campusEntityKey}
                  >
                    {campus.shortCampusDisplayName}
                  </option>
                ))}
              </select> 
            </div>

            <NavLink
              className={activePage === 'comfort' ? 'sidenav-link comfort active' : 'sidenav-link comfort'}
              to={`/comfort/${currentCampusId}`}
              onClick={() => handlePageChange('comfort')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_comfort}
                  alt='thermometer icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Comfort
              </div>
            </NavLink>

            <NavLink
              className={activePage === 'energy' ? 'sidenav-link energy active' : 'sidenav-link energy'}
              to={`/energy/${currentCampusId}`}
              onClick={() => handlePageChange('energy')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_energy}
                  alt='lighting bolt icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Energy
              </div>
            </NavLink>

            <NavLink
              className={activePage === 'devices' ? 'sidenav-link devices active' : 'sidenav-link devices'}
              to={`/devices/${currentCampusId}/ev`}
              onClick={() => handlePageChange('devices')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_devices}
                  alt='lighting bolt icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Devices
              </div>
            </NavLink>

            <NavLink
              className={activePage === 'scheduler' ? 'sidenav-link scheduler active' : 'sidenav-link scheduler'}
              to={`/scheduler/${currentCampusId}/schedules`}
              onClick={() => handlePageChange('scheduler')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_scheduler}
                  alt='calendar icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Scheduler
              </div>
            </NavLink>

            <NavLink
              className={activePage === 'resources' ? 'sidenav-link resources active' : 'sidenav-link resources'}
              to={'/resources'}
              onClick={() => handlePageChange('resources')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_resources}
                  alt='info icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Resources
              </div>
            </NavLink>

            {activePage === 'resources' && (
              <div className='subnav-resources'>
                <span>Support</span>

                <div className='support-details'>
                  <p>Hours: 9am - 5pm (PST)</p>
                  <p>Callback within: 
                    <br/>
                    1 hour (Weekdays)
                    <br/>
                    4 hours (Weekends)
                  </p>
                </div>

                <div className='contact'>
                  <a href='mailto:support@elexity.io' className='email-us'>
                    <img
                      src={icon_email}
                      alt='email icon'
                      width='14'
                      height='14'
                    />
                    support@elexity.io
                  </a>

                  <a href='tel:5102259329' className='call-us'>
                    <img
                      src={icon_phone}
                      alt='phone icon'
                      width='14'
                      height='14'
                    />
                    510-225-9329
                  </a>
                </div>
              </div>
            )}
          </div>
          
          <div className='sidenav-bottom'>
            <NavLink
              className={activePage === 'settings' ? 'sidenav-link settings active' : 'sidenav-link settings'}
              to={'/settings'}
              onClick={() => handlePageChange('settings')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_settings}
                  alt='gear icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Settings
              </div>
            </NavLink>

            {/* <NavLink
              className='sidenav-link notifications'
              to={'/notifications'}
              onClick={() => handlePageChange('notifications')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_notifications}
                  alt='bell icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Notifications
              </div>
            </NavLink> */}

            <a
              className='sidenav-link logout'
              onClick={authentication.logout}
              href={authentication.logoutLink}
            >
              <div className='menu-icon'>
                <img
                  src={icon_logout}
                  alt='logout icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Log Out
              </div>
            </a>
          </div>
        </div>
      </div>

      <div 
        className={showSidenav ? 'sidenav-overlay show' : 'sidenav-overlay'} 
        ref={overlayRef}
      ></div>
    </StyledSideNavDiv>
  </>)
}

const StyledSideNavDiv = styled.div`
  .sidenav-overlay.show {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background: rgba(52, 64, 84, 0.60);
    bottom: 0;
    display: block;
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transition: ease-in-out all 350ms;
    z-index: 105;
  }

  .sidenav {
    background: #EDF1F2;
    border-radius: 0 12px 12px 0;
    cursor: pointer;
    height: 100vh;
    position: absolute;
    text-align: left;
    transition: ease-in-out all 250ms;
    width: 300px;
    z-index: 110;
    
    //HIDDEN SIDENAV BEGIN//
    &.hide {
      min-width: unset;
      transition: ease-in-out all 250ms;
      width: 86px;

      .nav-arrow {
        transform: rotate(270deg);
      }

      .sidenav-main-container,
      .sidenav-main-container .campus-dropdown,
      .sidenav-main-container .user-container,
      .sidenav-main-container .menu-name {
        overflow: hidden;
        transition: ease-in-out width 0ms;
      }

      .sidenav-main-container {
        .sidenav-top,
        .sidenav-bottom {
          overflow: hidden;
          width: 36px;
        }

        .campus-dropdown,
        .user-container {
          width: 0;
        }

        .sidenav-link {
          &.active {
            background: transparent;

            .menu-icon {
              background: rgba(194, 208, 209, 0.5);
            }
          }

          .menu-icon {
            height: 24px;
            width: 24px;
          }
        }
      }

      .subnav-resources {
        display: none;
      }
    }
    //HIDDEN SIDENAV END//

    .sidenav-main-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: calc(100% - 48px);
      justify-content: space-between;
      overflow: hidden;
      padding: 24px 28px 0;
      transition: ease-in-out width 800ms;

      .sidenav-link {
        align-items: center;
        background-color: transparent;
        border-radius: 8px;
        color: #02222B;
        display: flex;
        font-weight: 500;
        height: 36px;
        justify-content: flex-start;
        margin-bottom: 6px;
        width: 252px;

        &.active {
          background: rgba(194, 208, 209, 0.5);
          border-radius: 8px;
          font-weight: 800;
        }

        &.resources .menu-name {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-right: 6px;
          width: 100%;
        }

        .menu-icon {
          align-items: center;
          background: transparent;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          margin-right: 6px;
          padding: 6px;
        }
      }

      .sidenav-top,
      .sidenav-bottom {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .campus-dropdown select {
        box-shadow: none;
        margin-bottom: 34px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 252px;
      }

      .campus-menu {
        color: #02222B;
        font-size: 14px;
      }

      .subnav-resources {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        font-weight: 600;
        margin: 0 24px;
        padding: 16px;

        span,
        a,
        p {
          color: #02222B;
        }

        a {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          margin: 6px 12px;
          text-decoration: underline;

          img {
            padding-right: 6px;
          }
        }

        span {
          margin-bottom: 12px;
        }

        .support-details {
          font-size: 12px;
          font-weight: 500;
          margin: 0 12px 5px;

          p {
            margin: 0 0 5px;
          }
        }

        .contact {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .sidenav-profile {
        align-items: center;
        border-top: #f4f5f7 2px solid;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        margin: 20px 24px 10px;
        padding: 15px 0;
      }

      .elexity-logo,
      .user-container {
        margin-left: 2px;
      }

      .user-container {
        align-items: center;
        color: #02222B;
        display: flex;
        justify-content: flex-start;

        .sidenav-profile-name {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          margin: 24px 0 12px;
          max-width: 252px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .nav-arrow {
      align-items: center;
      background: white;
      border-radius: 50px;
      box-shadow: rgba(194, 208, 209, .5) 0 0 10px;
      display: flex;
      height: 24px;
      justify-content: center;
      width: 24px;
      top: 28px;
      position: absolute;
      right: -10px;
      transform: rotate(90deg);
      z-index: 100;
    }
  }

  @media screen and (max-width: 768px) {
    .mobile-nav {
      align-items: center;
      background: #f8f9fb;
      display: flex;
      justify-content: space-between;
      padding: 12px 24px;
      width: calc(100% - 48px);
      position: fixed;
    }

    .sidenav.mobile-hide {
      display: none;
    }

    .mobile-logo {
      width: 55vw;
    }
  }
`

const mapStateToProps = (state) => {
  return {
    campuses: state.campuses,
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    currentGXData: state.currentGXData,
    currentUserData: state.currentUserData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
      dispatch(setCurrentCampus(campusId))
    },
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    },
    setCurrentGXData: (gxData) => {
      dispatch(setCurrentGXData(gxData))
    },
    setCurrentZone: (zoneId) => {
      dispatch(setCurrentZone(zoneId))
    },
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideNav))