import React from 'react';

import {connect} from 'react-redux'
import styled from '@emotion/styled'

import WithRouter from '../../utilities/WithRouter'
import { setCurrentCampus } from '../../actions'

const BatteryContent = ({ showMobile }) => {
    return (
        <div>No Battery connected</div>
    )
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentCampusData: state.currentCampusData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
        dispatch(setCurrentCampus(campusId))
    }
  }
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(BatteryContent))